import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import querystring from 'query-string';
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';

import {
  GetProgramsByRegion,
  GetAllCodes,
} from '../../store/actions/programsActions';
import {GetCurrentEmployee} from '../../store/actions/employeesActions';
import {
  GetAllWeeks,
  ChangeWeekStatus,
  SetSelectedWeek,
} from '../../store/actions/weeksActions';
import {ClearMessages} from '../../store/actions/messagesActions';

import AddEntryModal from '../weeks/AddEntryModal';
import * as moment from 'moment-timezone';
import DateHelpers from '../../utils/DateHelpers';
import isEmpty from '../../validation/isEmpty';
moment.tz ('America/Toronto').format ('Z');
moment.locale ('fr');
moment.updateLocale ('fr', {
  week: {
    dow: 0,
  },
});
class ViewPerWeek extends Component {
  constructor (props) {
    super (props);
    this.state = {
      year: DateHelpers.getFiscalYear (moment ().utc ()),
      period: DateHelpers.getPeriodNumber (moment ().utc ()),
      sunday: moment.utc ().format ('YYYY-MM-DD'),
      entries: [],
      total: 0,
      expectedTotal: 35,
      programsList: [],
      modal: '',
      status: '',
    };
    this.initialState = this.state;
  }
  /* ========== Life cycle methods */
  componentDidMount () {
    const {id} = this.props.auth.utilisateur;
    this.props.GetCurrentEmployee (id);
    const {entries} = this.state;

    this.UpdateYear ();
    this.setState ({total: entries.reduce (this.CalcWeeksTotal, 0)});

    //Shows a specific week from the url params
    if (!isEmpty (querystring.parse (this.props.location.search))) {
      const {year, period} = querystring.parse (this.props.location.search);
      const sunday = moment
        .utc ()
        .year (year)
        .month (8)
        .date (1)
        .day (0)
        .add (period - 1, 'week')
        .format ('YYYY-MM-DD');
      this.setState ({year, period, sunday}, () => {
        this.UpdateYear ();
      });
    }
    //this.GetWeekList (this.state.year);
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.weeks !== this.props.weeks) {
      if (this.props.weeks.weeks !== prevProps.weeks.weeks) {
        this.UpdateWeeks ();
      }
      if (this.props.weeks.selectedWeek !== prevProps.weeks.selectedWeek) {
        if (!isEmpty (this.props.weeks.selectedWeek)) {
          const {status} = this.props.weeks.selectedWeek;
          this.setState ({status});
        } else {
          this.setState ({status: this.initialState.status});
        }
      }
    }
    if (prevProps.employees !== this.props.employees) {
      if (this.props.employees.selectedEmployee) {
        const {region} = this.props.employees.selectedEmployee;
        const {hourPerWeek} = this.props.employees.selectedEmployee.contract;
        this.setState ({expectedTotal: hourPerWeek});

        this.props.GetProgramsByRegion (region);
      }
    }
    if (prevProps.programs !== this.props.programs) {
      if (
        prevProps.programs.programsList !== this.props.programs.programsList
      ) {
        const progIds = this.props.programs.programsList.map (p => {
          return p._id;
        });
        this.props.GetAllCodes (progIds);
      }
    }
    if (prevProps.messages !== this.props.messages) {
      if (
        this.props.messages.msg === 'ENTRY_ADDED' ||
        this.props.messages.msg === 'ENTRY_DELETED'
      ) {
        this.props.ClearMessages ();
        this.ClearModal ();
      }
    }
  }

  UpdateWeeks = () => {
    const {weeks} = this.props.weeks;
    const currentWeek = weeks.filter (this.FindSelectedWeek);
    let entries = [];
    //console.log (currentWeek);
    if (currentWeek !== undefined && currentWeek.length > 0) {
      entries = currentWeek[0].entries;
      this.props.SetSelectedWeek (currentWeek[0]);
    } else {
      this.props.SetSelectedWeek ({});
    }

    this.setState ({entries}, () => {
      this.SortEntries ();
      this.setState ({total: entries.reduce (this.CalcWeeksTotal, 0)});
    });
  };

  UpdateYear = () => {
    const {id} = this.props.auth.utilisateur;
    const {year} = this.state;
    this.props.GetAllWeeks (year, id);
  };

  FindSelectedWeek = week => {
    const {period} = this.state;
    return week.period.toString () === period.toString ();
  };

  /* =========== Pure functions */

  /* ============ Other functions */
  OnHandleSelect = e => {
    const state = this.state;
    state[e.currentTarget.name] = e.currentTarget.value;
    this.setState (state);
  };

  OnWeekChange = e => {
    const state = this.state;
    state.period = e.currentTarget.value;
    state.sunday = e.currentTarget.selectedOptions[0].dataset.sunday;
    this.setState (state, () => {
      this.UpdateWeeks ();
    });
  };

  OnYearChange = e => {
    const state = this.state;
    state.year = e.currentTarget.value;
    this.setState (state, () => {
      this.UpdateYear ();
    });
  };

  GetProgramColor = code => {
    const {programsList} = this.props.programs;
    if (isEmpty (programsList)) return;
    const program = programsList.find (prog => {
      return prog.prog_code === code;
    });
    return program.color || '#000000';
  };

  WeekSelect = () => {
    return (
      <div className="form-group">
        <label htmlFor="period">
          <h4>
            Semaine du
          </h4>
        </label>
        <select
          className="custom-select"
          name="period"
          id="period"
          onChange={this.OnWeekChange}
          value={this.state.period}
        >
          {this.WeekOptions ()}
        </select>
      </div>
    );
  };

  WeekOptions = () => {
    const {year} = this.state;
    const weekList = this.GetWeekList (year);
    return weekList.map (this.WeekOpt);
  };

  WeekOpt = (week, index) => {
    return (
      <option
        data-sunday={moment.utc (week).format ('YYYY-MM-DD')}
        value={DateHelpers.getPeriodNumber (
          moment.utc (week).format ('YYYY-MM-DD')
        )}
        key={index}
      >
        Période
        {' '}
        {DateHelpers.getPeriodNumber (moment.utc (week).format ('YYYY-MM-DD'))}
        {' '}
        |
        {' '}
        {moment.utc (week).format ('DD MMMM YYYY')}
      </option>
    );
  };

  YearSelect = () => {
    const {employementDate = null} = this.props.employees.selectedEmployee;
    return (
      <div className="form-group">
        <label htmlFor="year">
          <h4>
            Année scolaire
          </h4>
        </label>
        <select
          className="custom-select"
          name="year"
          id="year"
          onChange={this.OnYearChange}
          value={this.state.year}
        >
          {this.YearOptions ()}
        </select>
        {!employementDate &&
          <small>Attention, veuillez définir une date d'embauche</small>}
      </div>
    );
  };

  YearOptions = () => {
    const {employementDate = null} = this.props.employees.selectedEmployee;
    let actualDate = parseInt (moment ().format ('YYYY'));
    const date = !employementDate
      ? parseInt (moment ().format ('YYYY'))
      : parseInt (moment (employementDate).format ('YYYY'));
    const options = [];

    do {
      const opt = (
        <option key={actualDate} value={actualDate}>
          {actualDate}-{actualDate + 1}
        </option>
      );
      options.push (opt);
      actualDate = parseInt (actualDate) - 1;
    } while (actualDate >= date);

    return options;
  };

  GetWeekList = year => {
    let start = moment ().year (parseInt (year)).month (8).date (1).day (0);

    let end = moment ().year (parseInt (year) + 1).month (8).date (1).day (0);

    var arr = [];
    // Get sunday
    let tmp = start.clone ();

    while (tmp.isBefore (end, 'd')) {
      if (tmp.isSameOrAfter (end, 'd')) break;
      arr.push (tmp.format ('YYYY-MM-DD'));
      tmp.add (7, 'days');
    }
    return arr;
  };

  ViewProgressBar = () => {
    const {total, expectedTotal, entries} = this.state;
    const totals = entries.reduce (this.GroupByTotal, {});

    return (
      <div
        className="dflex flex-column tempo-progress-bar"
        style={{height: '100%'}}
      >
        <div>
          <h4>
            Progression {this.ViewTotal ()}
          </h4>
        </div>
        <div
          className="bar-container"
          style={{width: '100%', backgroundColor: 'lightGray', height: '50%'}}
        >
          {this.Bars (totals, expectedTotal, total)}
        </div>

        {total > expectedTotal &&
          <div>
            Attention,
            {' '}
            {total - expectedTotal}
            {' '}
            {total > expectedTotal + 1 ? 'heures' : 'heure'}
            {' '}
            en surplus
          </div>}
      </div>
    );
  };

  Bars = (groupedTotal, expectedTotal, total) => {
    const bars = [];
    const totalToChoose = expectedTotal > total ? expectedTotal : total;
    let index = 0;
    for (const prog in groupedTotal) {
      if (groupedTotal.hasOwnProperty (prog)) {
        const percent = groupedTotal[prog] / totalToChoose * 100;
        const color = this.GetProgramColor (prog);
        bars.push (
          <div
            key={index}
            className="bar"
            style={{
              display: 'inline-block',
              height: '100%',
              width: percent + '%',
              backgroundColor: color,
            }}
          />
        );
        index++;
      }
    }
    return bars;
  };

  CalcWeeksTotal = (acc, entry) => {
    return acc + parseFloat (entry.duration);
  };

  GroupByTotal = (acc, entry) => {
    const {prog_code} = entry.fiscalCode.code_program;
    const duration = parseFloat (acc[prog_code]) || 0;
    return {...acc, [prog_code]: duration + parseFloat (entry.duration)};
  };

  GroupByProgram = (acc, entry) => {
    const {prog_code} = entry.fiscalCode.code_program;
    const entriesArray = acc[prog_code] || [];

    return {...acc, [prog_code]: [...entriesArray, entry]};
  };

  ViewEntriesList = () => {
    const {entries} = this.state;

    if (entries.length === 0) {
      return (
        <div className="mt-3 week-list table-wrapper-scroll-y my-custom-scrollbar">
          <h5>
            Aucune entrée de temps pour cette semaine
          </h5>
        </div>
      );
    }

    return (
      <div className="week-list table-wrapper-scroll-y my-custom-scrollbar">
        <table
          className="table table-hover table-sm"
          style={{cellspacing: '0', width: '100%'}}
        >
          <tbody className=" " style={{height: '5%', overflowY: 'auto'}}>
            {entries.map (this.EntriesListRow)}
          </tbody>

        </table>
      </div>
    );
  };

  SortEntries = () => {
    const {entries} = this.state;

    entries.sort ((a, b) => {
      if (moment.utc (a.date).isBefore (b.date)) {
        return -1;
      } else if (moment.utc (a.date).isAfter (b.date)) {
        return 1;
      } else {
        return 0;
      }
    });

    this.setState ({entries});
  };

  EntriesListRow = (row, index) => {
    const {date, duration, comments} = row;
    const {code_description} = row.fiscalCode;
    const {prog_description, color} = row.fiscalCode.code_program;

    return (
      <tr
        key={index}
        style={{cursor: 'pointer'}}
        data-id={row._id}
        onClick={this.UpdateEntry}
      >
        <td>
          <div
            style={{
              width: '15px',
              height: '15px',
              borderRadius: '10px',
              backgroundColor: color,
            }}
          />
        </td>
        <td>{moment.utc (date).format ('YYYY-MM-DD')}</td>
        <td>{prog_description}</td>
        <td>{code_description}</td>
        <td>{duration} {duration > 1 ? 'hrs' : 'hr'}</td>
        {!isEmpty (comments) &&
          <td id={row.id}>
            <Tippy
              content={<span>{comments}</span>}
              placement="top-end"
              boundary="viewport"
            >
              <i className="fas fa-comment-dots" />
            </Tippy>
          </td>}
      </tr>
    );
  };

  ViewTotal = () => {
    const {total, expectedTotal} = this.state;
    return (
      <span>
        {total}
        {' '}
        /
        {' '}
        {expectedTotal}
        {' '}
        {expectedTotal > 1 ? 'hrs' : 'hr'}
      </span>
    );
  };

  Status = () => {
    const {status} = this.state;
    if (isEmpty (status)) return;

    let label = '';
    switch (status) {
      case 'PENDING':
        label = 'Semaine envoyée pour approbation';
        break;
      case 'CONDITIONAL':
        label = 'Vous devez modifier les codes ou certaines entrées de temps';
        break;
      case 'APPROVED':
        label = 'Semaine approuvée';
        break;
      case 'DENIED':
        label = 'Semaine refusée. Veuillez contacter votre superviseur.';
        break;
      case 'UNSENT':
      default:
        label = 'Semaine non envoyée pour approbation';
    }
    return (
      <div className="col-md-6">
        <h6>{label}</h6>
      </div>
    );
  };
  AddButton = () => {
    return (
      <div className=" add-btn" onClick={this.ShowModal}>
        <span><i className="fas fa-plus" /></span>
      </div>
    );
  };

  SendForApprobationButton = () => {
    if (isEmpty (this.props.weeks.selectedWeek)) return;
    const {_id} = this.props.weeks.selectedWeek;

    return (
      <div
        className="add-btn mx-5"
        onClick={() => {
          this.props.ChangeWeekStatus (_id, 'PENDING');
        }}
      >
        <span>
          <i className="fas fa-share" />
        </span>
      </div>
    );
  };

  ShowModal = () => {
    const {sunday} = this.state;
    const modal = (
      <AddEntryModal
        ClearModal={this.ClearModal}
        weekNo={this.state.weekNo}
        year={this.state.year}
        sunday={sunday}
      />
    );

    this.setState ({modal}, () => {
      document.getElementById ('modalEntry-btn').click ();
    });
  };

  FindEntry = id => {
    const {entries} = this.state;
    return entries.filter (entry => {
      return entry._id === id;
    })[0];
  };

  UpdateEntry = e => {
    if (this.state.status !== 'UNSENT' && this.state.status !== 'CONDITIONAL')
      return;
    const id = e.currentTarget.dataset.id;
    const entry = this.FindEntry (id);

    const modal = (
      <AddEntryModal
        ClearModal={this.ClearModal}
        weekNo={this.state.weekNo}
        year={this.state.year}
        retrievedState={entry}
        action="UPDATE"
      />
    );

    this.setState ({modal}, () => {
      document.getElementById ('modalEntry-btn').click ();
    });
  };

  ClearModal = () => {
    document.getElementById ('closeModalBtn').click ();
    this.setState ({modal: ''});
  };

  render () {
    const {status} = this.state;
    return (
      <div className="container">
        {this.state.modal}
        <div className="row my-3 py-1">
          <div className="col-md-12 py-4">
            <Link to="/sommaire">
              <h3>
                <i className="fas fa-calendar-week px-3" />
                Voir la liste des semaines
              </h3>
            </Link>
          </div>
        </div>
        <div className="row py-4 week-page">
          <div className="col-md-3">
            {this.YearSelect ()}
          </div>
          <div className="col-md-3">
            {this.WeekSelect ()}
          </div>
          <div className="col-md-6">
            {this.ViewProgressBar ()}
          </div>
        </div>

        <div className="row py-4 week-page">
          {(status === 'UNSENT' ||
            isEmpty (status) ||
            status === 'CONDITIONAL') &&
            this.AddButton ()}
          {(status === 'UNSENT' ||
            isEmpty (status) ||
            status === 'CONDITIONAL') &&
            this.SendForApprobationButton ()}
          {this.Status ()}
          {this.ViewEntriesList ()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  employees: state.employees,
  programs: state.programs,
  weeks: state.weeks,
  messages: state.messages,
});
export default connect (mapStateToProps, {
  GetProgramsByRegion,
  GetAllWeeks,
  GetAllCodes,
  ClearMessages,
  GetCurrentEmployee,
  ChangeWeekStatus,
  SetSelectedWeek,
}) (ViewPerWeek);
