import {GET_MESSAGES, CLEAR_MESSAGES, CLEAR_ALL} from '../actions/types';

const initialState = {
  msg: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MESSAGES:
      return {...state, msg: action.payload};
    case (CLEAR_MESSAGES, CLEAR_ALL):
      return initialState;
    default:
      return state;
  }
}
