import React, {Component} from 'react';
import './App.scss';
import {Provider} from 'react-redux';
import store from './store/store';
import setAuthHeader from './utils/setAuthHeaders';
import jwt_decode from 'jwt-decode';
import {setCurrentUser, logoutUser} from './store/actions/authActions';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import PrivateRoute from './components/auth/PrivateRoute';
import AdminRoute from './components/auth/AdminRoute';

//Components
import Page404 from './components/pages/Page404';
import Navbar from './components/pages/partials/Navbar';
import Connexion from './components/auth/ConnexionForm';

//Pages utilisateurs
import ViewPerWeek from './components/weeks/ViewPerWeek';
import ViewAllWeeks from './components/weeks/ViewAllWeeks';
import ViewOvertimePerEmployee
  from './components/weeks/ViewOvertimePerEmployee';

//Pages Profil
import ViewProfile from './components/weeks/ViewProfile';
import EmergencyContact from './components/pages/profile/EmergencyContact';

//Pages Admin
import SummaryAdmin from './components/weeks/AdminEmployeeSummary';
import AdminWeeksToApprove from './components/weeks/AdminWeeksToApprove';
import FiscalCodesAdmin from './components/pages/admin/FiscalCodes';
import UsersManagementAdmin from './components/pages/admin/UsersManagement';
import AdminWeeksView from './components/weeks/AdminWeeksView';
import AdminEntryView from './components/weeks/AdminEntryView';
import ExportData from './components/pages/admin/ExportData';

//AUTH CHECK
//if there is a token already in localstorage,
//Allows to keep users info if page reloads
if (localStorage.techscTempo) {
  //Set authorization header for each request
  setAuthHeader (localStorage.techscTempo);
  //Decode user's token
  const decoded = jwt_decode (localStorage.techscTempo);
  //Set current user and authentication
  store.dispatch (setCurrentUser (decoded));

  //Checks expired token
  const currentTime = Date.now () / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch (logoutUser ());
    //Redirects
    window.location.href = '/connexion';
  }
}

class App extends Component {
  render () {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Navbar />
            <div className="container">
              <Switch>
                <Route exact path="/connexion" component={Connexion} />

                <PrivateRoute exact path="/" component={ViewPerWeek} />

                <PrivateRoute exact path="/sommaire" component={ViewAllWeeks} />

                <PrivateRoute exact path="/profil" component={ViewProfile} />
                <PrivateRoute
                  exact
                  path="/urgence"
                  component={EmergencyContact}
                />
                <PrivateRoute
                  exact
                  path="/temps-supplementaire"
                  component={ViewOvertimePerEmployee}
                />

                <AdminRoute
                  exact
                  path="/admin/sommaire"
                  component={SummaryAdmin}
                />
                <AdminRoute
                  exact
                  path="/admin/a-approuver"
                  component={AdminWeeksToApprove}
                />
                <AdminRoute
                  exact
                  path="/admin/indices-comptables"
                  component={FiscalCodesAdmin}
                />
                <AdminRoute
                  exact
                  path="/admin/utilisateurs"
                  component={UsersManagementAdmin}
                />
                <AdminRoute
                  exact
                  path="/admin/semaines/:id"
                  component={AdminWeeksView}
                />
                <AdminRoute
                  exact
                  path="/admin/entrees/:id"
                  component={AdminEntryView}
                />
                <AdminRoute
                  exact
                  path="/admin/exportation"
                  component={ExportData}
                />

                <Route exact path="/non-trouve" component={Page404} />
              </Switch>
            </div>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
