var roles = {
	ADMIN: 1,
	SUPERVISEUR: 2,
	EMPLOYE: 3,
	BENEVOLE: 4,
	STAGIAIRE: 5
};

var properties = {
	1: { id: 1, name: "Administrateur(trice)" },
	2: { id: 2, name: "Superviseur(e)" },
	3: { id: 3, name: "Employé(e)" },
	4: { id: 4, name: "Bénévole" },
	5: { id: 5, name: "Stagiaire" }
};

//Liste les propriétés
const values = [];

for (let i in properties) {
	values.push(properties[i]);
}

//Retourne la liste des constantes lorsqu'exporté
module.exports = roles;
//Retourne un tableau de toutes les régions
module.exports.values = values;
//Retour l'objet contenant les id ,noms
module.exports.properties = properties;
//FONCTION pour récupérer une région
module.exports.get = id => {
	if (properties[id] !== undefined) {
		return properties[id];
	}
};
