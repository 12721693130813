import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';

import {GetAllPrograms, GetAllCodes} from '../../store/actions/programsActions';

class FiscalCodesList extends Component {
  render () {
    const programsList = this.props.programs.programsList.map (prog => {
      return (
        <div className="row my-3" key={prog._id}>
          <div className="col">
            <h4
              className="text-align-left"
              style={{color: prog.color || '#000000'}}
            >
              {prog.prog_code.toUpperCase ()} &mdash; {prog.prog_description}
            </h4>
            {this.props.programs.fiscalCodesList.map (code => {
              if (prog._id === code.code_program._id) {
                return (
                  <Fragment key={code._id}>
                    <div className="row codes-list">
                      <div className="col-md-2 pt-2">
                        {code.code_id}
                      </div>
                      <div className="col pt-2">{code.code_description}</div>
                      <div className="ml-auto btn btn-reseau">
                        <i className="fas fa-edit" />
                      </div>
                      <div className="ml-auto btn btn-reseau">
                        <i className="fas fa-trash" />
                      </div>
                    </div>
                  </Fragment>
                );
              }
              return true;
            })}
          </div>
        </div>
      );
    });
    return (
      <Fragment>
        {programsList}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  programs: state.programs,
});

export default connect (mapStateToProps, {GetAllPrograms, GetAllCodes}) (
  FiscalCodesList
);
