import * as moment from 'moment-timezone';
moment.tz ('America/Toronto').format ('Z');
moment.updateLocale ('en', {
  week: {
    dow: 0,
  },
});
export default class DateHelper {
  static getPeriodNumber = d => {
    const fiscalYear = this.getFiscalYear (d);
    const startDate = moment ()
      .utc ()
      .date (1)
      .month (8)
      .year (fiscalYear)
      .day (0)
      .subtract (1, 'day');
    const searchDate = moment.utc (d).day (0);

    return searchDate.diff (startDate, 'week') + 1;
  };

  static cleanDate = date => {
    var stillUtc = moment.utc (date).toDate ();
    var local = moment (stillUtc).local ().format ('YYYY-MM-DD');
    return local;
  };

  static getFiscalYear = date => {
    const year = moment.utc (date).format ('YYYY');
    const firstSunday = moment
      .utc ()
      .year (parseInt (year))
      .month (8)
      .date (1)
      .day (0);

    return moment.utc (date).isBefore (firstSunday, 'day')
      ? moment.utc (date).year () - 1
      : moment.utc (date).year ();
  };
}
