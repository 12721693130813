import React, {Component} from 'react';
import {connect} from 'react-redux';
import {login} from '../../store/actions/authActions';
import {withRouter} from 'react-router-dom';
import classnames from 'classnames';

class ConnexionForm extends Component {
  constructor (props) {
    super (props);
    this.state = {
      email: '',
      pwd: '',
      errors: {},
    };

    this.onChange = this.onChange.bind (this);
    this.onSubmit = this.onSubmit.bind (this);
  }

  componentDidMount () {
    // Si déjà authentifié, aller directement au dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push ('/');
    }
  }
  onSubmit (e) {
    e.preventDefault ();

    const user = {
      email: this.state.email,
      pwd: this.state.pwd,
    };

    this.props.login (user, this.props.history);
  }

  onChange (e) {
    this.setState ({[e.target.name]: e.target.value});
  }

  render () {
    const errors = this.props.errors;
    const login = (
      <div className="login">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center">Connexion</h1>
              <p className="lead text-center">
                Tempo, le système de suivi du temps travaillé
              </p>
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <input
                    type="email"
                    className={classnames ('form-control form-control-lg', {
                      'is-invalid': errors.email,
                    })}
                    placeholder="Courriel"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChange}
                  />
                  {errors.email &&
                    <div className="invalid-feedback">{errors.email}</div>}
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className={classnames ('form-control form-control-lg', {
                      'is-invalid': errors.pwd,
                    })}
                    placeholder="Mot de passe"
                    name="pwd"
                    value={this.state.pwd}
                    onChange={this.onChange}
                  />
                  {errors.pwd &&
                    <div className="invalid-feedback">{errors.pwd}</div>}
                </div>
                <input type="submit" className="btn btn-info btn-block mt-4" />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
    return !this.props.auth.isAuthenticated && login;
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect (mapStateToProps, {login}) (withRouter (ConnexionForm));
