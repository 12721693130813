import {
  GET_ALL_PROGRAMS,
  GET_ALL_CODES,
  ADD_PROGRAM,
  ADD_CODE,
  GET_ERRORS,
} from './types';

import axios from 'axios';

export const GetAllPrograms = () => dispatch => {
  axios
    .get ('/api/programme/tous')
    .then (programmesList => {
      dispatch ({type: GET_ALL_PROGRAMS, payload: programmesList.data});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err});
    });
};
export const GetProgramsByRegion = region => dispatch => {
  axios
    .get ('/api/programme/region', {params: {region}})
    .then (programmesList => {
      dispatch ({type: GET_ALL_PROGRAMS, payload: programmesList.data});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err});
    });
};

export const GetAllCodes = (programs = null) => dispatch => {
  axios
    .get ('/api/programme/codes', {params: {programs}})
    .then (programmesList => {
      dispatch ({type: GET_ALL_CODES, payload: programmesList.data});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};

export const AddProgram = data => dispatch => {
  axios
    .post ('/api/programme', data)
    .then (programmesList => {
      dispatch ({type: ADD_PROGRAM, payload: programmesList.data});
    })
    .then (() => {
      dispatch (GetAllPrograms ());
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};

export const AddCode = data => dispatch => {
  axios
    .post ('/api/programme/code', data)
    .then (programmesList => {
      dispatch ({type: ADD_CODE, payload: programmesList.data});
    })
    .then (() => {
      dispatch (GetAllCodes ());
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};
