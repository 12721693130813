import React, {Component} from 'react';
import {connect} from 'react-redux';
import {GetCurrentEmployee} from '../../store/actions/employeesActions';
import {GetAllWeeks} from '../../store/actions/weeksActions';
import * as moment from 'moment-timezone';
import localization from 'moment/locale/fr';
import DateHelpers from '../../utils/DateHelpers';
import isEmpty from '../../validation/isEmpty';
moment.tz ('America/Toronto').format ('Z');
moment.locale ('fr');
moment.updateLocale ('fr', {
  week: {
    dow: 0,
  },
});
const MAX_HOURS_PER_WEEK = 40;

export class ViewOvertimePerEmployee extends Component {
  constructor (props) {
    super (props);
    this.state = {
      year: DateHelpers.getFiscalYear (moment ().utc ()),
      total: 0,
      totalOvertime: 0,
      usedOvertimeEntries: [],
      overtimeWeeks: [],
    };
    this.initialState = this.state;
  }
  componentDidMount () {
    const {year} = this.state;
    const {id} = this.props.auth.utilisateur;

    this.props.GetCurrentEmployee (id);
    this.props.GetAllWeeks (year, id);
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.weeks !== this.props.weeks) {
      if (this.props.weeks.weeks !== prevProps.weeks.weeks) {
        this.FindUsedOvertime ();
        this.FindOvertime ();
      }
    }
  }
  CalculateOvertime = (totalHours, expectedHours) => {
    if (expectedHours > totalHours) return;

    const hoursBeforeMax = totalHours > MAX_HOURS_PER_WEEK
      ? MAX_HOURS_PER_WEEK - expectedHours
      : totalHours - expectedHours;
    const hoursAfterMax = totalHours > MAX_HOURS_PER_WEEK
      ? (totalHours - MAX_HOURS_PER_WEEK) * 1.5
      : 0;
    return hoursBeforeMax + hoursAfterMax;
  };

  FindOvertime = () => {
    const {weeks} = this.props.weeks;
    const {selectedEmployee} = this.props.employees;
    if (isEmpty (weeks) || isEmpty (selectedEmployee)) return;

    const {hourPerWeek} = selectedEmployee.contract;
    const overtimeWeeks = [];
    let totalOvertime = 0;

    weeks.map (week => {
      const totalHours = week.entries.reduce ((acc, current) => {
        return acc + current.duration;
      }, 0);

      if (totalHours > hourPerWeek) {
        const weekOvertime = this.CalculateOvertime (totalHours, hourPerWeek);
        totalOvertime += weekOvertime;
        overtimeWeeks.push ({week, weekOvertime, totalHours});
      }
      return true;
    });

    overtimeWeeks.sort ((a, b) => {
      return a.period > b.period;
    });
    this.setState ({overtimeWeeks, totalOvertime});
  };

  FindUsedOvertime = () => {
    const {weeks} = this.props.weeks;
    if (isEmpty (weeks)) return;
    const usedOvertimeEntries = [];

    weeks.map (week => {
      week.entries.map (e => {
        if (e.fiscalCode.code_id === '999') {
          usedOvertimeEntries.push (e);
        }
        return true;
      });
      return true;
    });

    usedOvertimeEntries.sort ((a, b) => {
      return moment ().utc (a.date).isAfter (b.date, 'day');
    });
    this.setState ({usedOvertimeEntries}, () => {
      const total = this.FindUsedTotal (this.state.usedOvertimeEntries);
      this.setState ({total});
    });
  };

  FindUsedTotal = arr => {
    return arr.reduce ((acc, current) => {
      return acc + current.duration;
    }, 0);
  };

  RenderHeader = () => {
    return (
      <div className="row my-3 py-1">
        <div className="col-md-12">
          <h1>Temps supplémentaires à reprendre</h1>
        </div>
      </div>
    );
  };

  RenderUsedOvertime = () => {
    const {total, year} = this.state;

    return (
      <div className="row my-2">
        <div className="col-md-12 my-3">
          <h3 className="info-color">Temps repris</h3>
          <h6>Total pour {year} : {total} hrs</h6>
        </div>
        <div className="col-md-12">
          {this.UsedOvertimeRows ()}
        </div>
      </div>
    );
  };
  UsedOvertimeRows = () => {
    const {usedOvertimeEntries} = this.state;
    return usedOvertimeEntries.map (elem => {
      return (
        <div className="row">
          <div className="col-md-4">
            <h6>{moment.utc (elem.date).format ('YYYY-MM-DD')}</h6>
          </div>
          <div className="col-md-4">
            <h6>{elem.duration} hrs</h6>
          </div>
        </div>
      );
    });
  };

  RenderOvertimeWeeks = () => {
    const {year, totalOvertime} = this.state;

    return (
      <div className="row">
        <div className="col-md-12 my-3">
          <h3 className="info-color">Semaines en surtemps</h3>
          <h6>Total pour {year} : {totalOvertime} hrs</h6>
        </div>
        <div className="col-md-12">
          {this.OverTimeRows ()}
        </div>
      </div>
    );
  };
  OverTimeRows = () => {
    const {overtimeWeeks} = this.state;
    return overtimeWeeks.map (elem => {
      return (
        <div className="row">
          <div className="col-md-6">
            <h5>
              Période
              {' '}
              {elem.week.period}
              {' '}
              - du
              {' '}
              {moment.utc (elem.week.weekStart).format ('YYYY-MM-DD')}
              {' '}
              au
              {' '}
              {moment.utc (elem.week.weekEnd).format ('YYYY-MM-DD')}
            </h5>
          </div>
          <div className="col-md-3">
            <h6>
              Temps travaillé:
              {' '}
              {elem.totalHours}
              {' '}
              hrs
            </h6>
          </div>
          <div className="col-md-3">
            <h6>
              Temps accumulé:
              {' '}
              {elem.weekOvertime}
              {' '}
              hrs
            </h6>
          </div>
        </div>
      );
    });
  };

  RenderOvertimeBalance = () => {
    const {total, totalOvertime} = this.state;

    return (
      <div className="row my-2">
        <div className="col-md-12">
          {total === totalOvertime
            ? <h3>Vous n'avez pas de temps supplémentaire à reprendre</h3>
            : total > totalOvertime
                ? <h2 className="alert-color">
                    <i className="fa fa-exclamation-triangle" />
                    Vous avez repris trop d'heures. Vous devez
                    {' '}
                    {total - totalOvertime}
                    {' '}
                    hrs.
                  </h2>
                : <h2 className="info-color">
                    <i className="fa fa-exclamation-circle" />
                    {' '}
                    Vous avez des heures en banque. Vous devez reprendre
                    {' '}
                    {totalOvertime - total}
                    {' '}
                    hrs.
                  </h2>}
        </div>
      </div>
    );
  };

  render () {
    return (
      <div className="container py-3">
        {this.RenderHeader ()}
        {this.RenderOvertimeBalance ()}
        {this.RenderOvertimeWeeks ()}
        {this.RenderUsedOvertime ()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  weeks: state.weeks,
  employees: state.employees,
});

const mapDispatchToProps = {
  GetCurrentEmployee,
  GetAllWeeks,
};

export default connect (mapStateToProps, mapDispatchToProps) (
  ViewOvertimePerEmployee
);
