export const GET_ALL_PROGRAMS = 'GET_ALL_PROGRAMS';
export const GET_ALL_CODES = 'GET_ALL_CODES';
export const GET_PROGRAM_ID = 'GET_PROGRAM_ID';
export const ADD_PROGRAM = 'ADD_PROGRAM';
export const ADD_CODE = 'ADD_CODE';
export const UPDATE_PROGRAM = 'UPDATE_PROGRAM';
export const DELETE_PROGRAM = 'DELETE_PROGRAM';

export const GET_ALL_WEEKS = 'GET_ALL_WEEKS';
export const GET_ALL_WEEKS_USERID = 'GET_ALL_WEEKS_USERID';
export const GET_ALL_WEEKS_PROGRAMID = 'GET_ALL_WEEKS_PROGRAMID';
export const GET_WEEK_ID = 'GET_WEEK_ID';
export const GET_ALL_PERIODS = 'GET_ALL_PERIODS';
export const GET_ALL_YEARS = 'GET_ALL_YEARS';
export const ADD_WEEK = 'ADD_WEEK';
export const ADD_TIME_PERIOD = 'ADD_TIME_PERIOD';
export const UPDATE_WEEK = 'UPDATE_WEEK';
export const DELETE_WEEK = 'DELETE_WEEK';
export const SET_YEAR = 'SET_YEAR';
export const SET_CURRENT_WEEK = 'SET_CURRENT_WEEK';

export const ADD_ENTRY = 'ADD_ENTRY';
export const ASSIGN_ENTRY_WEEK = 'ASSIGN_ENTRY_WEEK';

export const GET_ALL_REGIONS = 'GET_ALL_REGIONS';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const ARCHIVE_USER = 'ARCHIVE_USER';

export const GET_ALL_EMPLOYEES = 'GET_ALL_EMPLOYEES';
export const GET_CURRENT_EMPLOYEE = 'GET_CURRENT_EMPLOYEE';
export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const ARCHIVE_EMPLOYEE = 'ARCHIVE_EMPLOYEE';

export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const GET_MESSAGES = 'GET_MESSAGES';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';

export const CLEAR_ALL = 'CLEAR_ALL';
