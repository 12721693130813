import {
  ADD_ENTRY,
  GET_ERRORS,
  ASSIGN_ENTRY_WEEK,
  GET_ALL_WEEKS,
  GET_MESSAGES,
  SET_CURRENT_WEEK,
} from './types';

import axios from 'axios';
import DateHelper from '../../utils/DateHelpers';

export const AddEntry = entry => dispatch => {
  axios
    .post ('/api/entree/creer', entry)
    .then (doc => {
      dispatch ({type: ADD_ENTRY});
      return axios.post ('/api/semaine/assigner-entree', {
        entryData: doc.data,
      });
    })
    .then (response => {
      return dispatch ({type: ASSIGN_ENTRY_WEEK, payload: response.data});
    })
    .then (response => {
      dispatch (
        GetAllWeeks (response.payload.fiscalYear, response.payload.employeId)
      );
      dispatch ({type: GET_MESSAGES, payload: 'ENTRY_ADDED'});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};

export const UpdateEntry = entry => dispatch => {
  axios
    .put ('/api/entree/modifier', entry)
    .then (response => {
      const fiscalYear = DateHelper.getFiscalYear (response.data.date);
      const {employeeId} = response.data;
      dispatch (GetAllWeeks (fiscalYear, employeeId));
      dispatch ({type: GET_MESSAGES, payload: 'ENTRY_DELETED'});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};

export const RemoveEntry = id => dispatch => {
  axios
    .delete ('/api/entree/supprimer', {params: {id}})
    .then (response => {
      const fiscalYear = DateHelper.getFiscalYear (response.data.date);
      const {employeeId} = response.data;
      dispatch (GetAllWeeks (fiscalYear, employeeId));
      dispatch ({type: GET_MESSAGES, payload: 'ENTRY_DELETED'});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};

export const GetAllWeeks = (fiscalYear, employeId) => dispatch => {
  axios
    .get ('/api/semaine/tous', {params: {fiscalYear, employeId}})
    .then (weekList => {
      dispatch ({type: GET_ALL_WEEKS, payload: weekList.data});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};

export const GetAllPendingWeeks = () => dispatch => {
  axios
    .get ('/api/semaine/a-approuver')
    .then (weekList => {
      dispatch ({type: GET_ALL_WEEKS, payload: weekList.data});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};

export const ExportAllWeeksByDate = (
  rangeStart,
  rangeEnd,
  selectedEmployees
) => dispatch => {
  axios
    .get ('/api/semaine/exportation/date', {
      params: {rangeStart, rangeEnd, selectedEmployees},
    })
    .then (weekList => {
      dispatch ({type: GET_ALL_WEEKS, payload: weekList.data});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};
export const ExportAllWeeksByPeriod = (
  fiscalYear,
  period,
  selectedEmployees
) => dispatch => {
  axios
    .get ('/api/semaine/exportation/period', {
      params: {fiscalYear, period, selectedEmployees},
    })
    .then (weekList => {
      dispatch ({type: GET_ALL_WEEKS, payload: weekList.data});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};

export const SetSelectedWeek = week => dispatch => {
  dispatch ({type: SET_CURRENT_WEEK, payload: week});
};

export const ChangeWeekStatus = (_id, status) => dispatch => {
  axios
    .put ('/api/semaine/changer-status', {_id, status})
    .then (updatedWeek => {
      dispatch (
        GetAllWeeks (updatedWeek.data.fiscalYear, updatedWeek.data.employeId)
      );
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};
export const ApproveWeek = (_id, status) => dispatch => {
  axios
    .put ('/api/semaine/changer-status', {_id, status})
    .then (updatedWeek => {
      dispatch (GetAllPendingWeeks ());
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};
