import {combineReducers} from 'redux';
import employeesReducer from './employeesReducer';
import programsReducer from './programsReducer';
import weeksReducer from './weeksReducer';
import regionsReducer from './regionsReducer';
import authReducer from './authReducer';
import errorsReducer from './errorsReducer';
import messagesReducer from './messagesReducer';

export default combineReducers ({
  employees: employeesReducer,
  programs: programsReducer,
  weeks: weeksReducer,
  regions: regionsReducer,
  auth: authReducer,
  errors: errorsReducer,
  messages: messagesReducer,
});
