import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';
import {AddProgram, AddCode} from '../../store/actions/programsActions';
import isEmpty from '../../validation/isEmpty';

class NewProgrammModal extends Component {
  constructor (props) {
    super (props);
    this.state = {
      selection: 'code',
      prog_code: '',
      prog_description: '',
      code_program: '',
      code_id: '',
      code_description: '',
      color: '#000000',
    };
  }

  AddProgram = () => {
    if (
      !isEmpty (this.state.prog_code) &&
      !isEmpty (this.state.prog_description) &&
      !isEmpty (this.state.color)
    ) {
      this.props.AddProgram ({
        prog_code: this.state.prog_code,
        prog_description: this.state.prog_description,
        color: this.state.color,
      });
      this.props.ClearModal ();
    }
  };

  AddCode = () => {
    if (
      !isEmpty (this.state.code_program) &&
      !isEmpty (this.state.code_id) &&
      !isEmpty (this.state.code_description)
    ) {
      this.props.AddCode ({
        code_program: this.state.code_program,
        code_id: this.state.code_id,
        code_description: this.state.code_description,
      });
      this.props.ClearModal ();
    }
  };

  HandleSelect = e => {
    this.setState ({[e.currentTarget.name]: e.currentTarget.value});
  };

  HandleCheck = e => {
    this.setState ({selection: e.currentTarget.value});
  };
  HandleText = e => {
    this.setState ({[e.currentTarget.name]: e.currentTarget.value});
  };
  render () {
    const progOpts =
      this.props.programs &&
      this.props.programs.programsList.map (program => {
        return (
          <option key={program._id} value={program._id}>
            {program.prog_code} - {program.prog_description}
          </option>
        );
      });
    const {
      selection,
      prog_code,
      prog_description,
      code_program,
      code_id,
      code_description,
      color,
    } = this.state;

    let isInvalid = false;
    if (
      selection === 'programme' &&
      (isEmpty (prog_code) || isEmpty (prog_description) || isEmpty (color))
    ) {
      isInvalid = true;
    }
    if (
      selection === 'code' &&
      (isEmpty (code_program) ||
        isEmpty (code_description) ||
        isEmpty (code_id))
    ) {
      isInvalid = true;
    }
    return (
      <Fragment>
        <button
          type="button"
          className="btn btn-primary d-none"
          data-toggle="modal"
          data-target="#modalNewProgram"
          id="modalProgram-btn"
        />
        <div
          className="modal fade"
          id="modalNewProgram"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modalProgramLabel"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content ">
              <div className="modal-header">
                <h3 className="modal-title text-center" id="modalProgramLabel">
                  Nouvel indice comptable
                </h3>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="closeModalBtn"
                  onClick={this.props.ClearModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <hr />
              <div className="modal-body" id="modalProgram-body">
                <h4>
                  Saisir l'accronyme du programme et le code de l'indice comptable
                </h4>
                {/* SELECTION OF PROGRAM OR CODE */}
                <div className="row">
                  <div className="form-check col">
                    <label className="form-check-label mr-5 p-5">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="selection"
                        id=""
                        value="programme"
                        onChange={this.HandleCheck}
                        checked={this.state.selection === 'programme'}
                      />
                      Nouveau programme
                    </label>
                    <label className="form-check-label">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="selection"
                        id=""
                        value="code"
                        onChange={this.HandleCheck}
                        checked={this.state.selection === 'code'}
                      />
                      Nouvel indice comptable
                    </label>
                  </div>
                </div>
                {/* PROGRAM FORM */}
                {this.state.selection === 'programme' &&
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="input-nom-complet" />
                        <input
                          type="text"
                          className="form-control"
                          name="prog_code"
                          id="input-nom-complet"
                          placeholder="Acronyme du programme"
                          onChange={this.HandleText}
                          value={this.state.prog_code}
                        />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label htmlFor="input-nom-complet" />
                        <input
                          type="text"
                          className="form-control"
                          name="prog_description"
                          id="input-nom-complet"
                          placeholder="Nom complet du programme"
                          onChange={this.HandleText}
                          value={this.state.prog_description}
                        />
                      </div>
                    </div>
                    <div className="col-md-1">
                      <div className="form-group">
                        <label htmlFor="input-nom-complet" />
                        <input
                          type="color"
                          className="form-control"
                          name="color"
                          id="input-nom-complet"
                          placeholder="Couleur"
                          onChange={this.HandleText}
                          value={this.state.color}
                        />
                      </div>
                    </div>
                  </div>}

                {/* CODE FORM */}
                {this.state.selection === 'code' &&
                  <div className="row">
                    <div className="form-group col-md-12">
                      <label htmlFor="">Programme</label>
                      <select
                        className="form-control"
                        name="code_program"
                        id="code_program"
                        onChange={this.HandleSelect}
                      >
                        <option value="">Choisir un programme</option>
                        {progOpts}
                      </select>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="input-indice-code">
                          Code de l'indice comptable
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="code_id"
                          id="input-indice-code"
                          placeholder="Code"
                          onChange={this.HandleText}
                          value={this.state.code_id}
                        />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="form-group">
                        <label htmlFor="input-indice-description">
                          Description
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="code_description"
                          id="input-indice-description"
                          placeholder="Description de l'indice comptable"
                          onChange={this.HandleText}
                          value={this.state.code_description}
                        />
                      </div>
                    </div>
                  </div>}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className={classnames ('btn btn-primary', {
                    'btn-disabled': isInvalid,
                  })}
                  onClick={
                    this.state.selection === 'programme'
                      ? this.AddProgram
                      : this.state.selection === 'code' ? this.AddCode : null
                  }
                >
                  Ajouter
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={this.props.ClearModal}
                >
                  Annuler
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  programs: state.programs,
});

export default connect (mapStateToProps, {AddProgram, AddCode}) (
  NewProgrammModal
);
