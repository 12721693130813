import { GET_ERRORS, CLEAR_ERRORS, CLEAR_ALL } from "../actions/types";

const initialState = {};

export default function(state = initialState, action) {
	switch (action.type) {
		case GET_ERRORS:
			return action.payload;
		case (CLEAR_ERRORS, CLEAR_ALL):
			return initialState;
		default:
			return state;
	}
}
