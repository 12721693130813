// import action
import {GET_ALL_REGIONS} from '../actions/types';

const initialState = {
  regions: [],
};
const weeksReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_REGIONS:
      return {...state, regions: action.payload};
    default:
      return state;
  }
};

export default weeksReducer;
