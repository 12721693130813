import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect, Link} from 'react-router-dom';
import querystring from 'query-string';
import moment from 'moment';
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';

import {GetEmployee} from '../../store/actions/employeesActions';
import {GetAllWeeks} from '../../store/actions/weeksActions';
import isEmpty from '../../validation/isEmpty';
export class AdminEntryView extends Component {
  constructor (props) {
    super (props);

    this.state = {
      year: 2019,
      period: 1,
      id: '',
      week: {
        weekStart: '',
        weekEnd: '',
        entries: [],
        employeId: {},
      },
      redirect: false,
    };
  }

  componentDidMount () {
    const {year, period} = querystring.parse (this.props.location.search);
    const {id} = this.props.match.params;
    this.setState ({year, period, id}, () => {
      this.props.GetEmployee (id);
      this.props.GetAllWeeks (year, id);
    });
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.weeks !== this.props.weeks) {
      const weekToShow = this.props.weeks.weeks.filter (w => {
        return w.period == this.state.period;
      });
      // Gets week
      const sortedWeek = this.SortWeeks (weekToShow[0]);
      this.setState ({week: sortedWeek});
    }
  }

  SortWeeks = week => {
    const entries = week.entries;
    entries.sort ((a, b) => {
      if (moment.utc (a.date).isBefore (b.date)) {
        return -1;
      } else if (moment.utc (a.date).isAfter (b.date)) {
        return 1;
      } else {
        return 0;
      }
    });
    week.entries = entries;
    return week;
  };

  RenderHeader = () => {
    const {firstName, lastName} = this.props.employees.selectedEmployee;
    const {period, week, id} = this.state;
    const {weekEnd, weekStart} = week;
    return (
      <div className="row my-3 py-1">
        <div className="col-md-12">
          <h1>{`${firstName} ${lastName}`}</h1>
          <h4
          >{`Semaine ${period} - du ${moment
            .utc (weekStart)
            .format (
              'YYYY-MM-DD'
            )} au ${moment.utc (weekEnd).format ('YYYY-MM-DD')}`}</h4>
          <Link to={`/admin/semaines/${id}`}>
            <h6
            >{`Retour à la liste des semaines travaillés pour ${firstName} ${lastName}`}</h6>
          </Link>
        </div>
      </div>
    );
  };

  RenderTotal = () => {
    const {entries, employeId} = this.state.week;
    if (isEmpty (employeId)) return;
    const {hourPerWeek} = employeId.contract;
    const total = entries.reduce ((acc, current) => {
      return acc + current.duration;
    }, 0);
    return (
      <div className="row py-3">
        <div className="col-md-12">
          <h3
          >{`Total des heures : ${total} ${total > 1 ? 'hrs' : 'hr'} / ${hourPerWeek} ${hourPerWeek > 1 ? 'hrs' : 'hr'}`}</h3>
        </div>
      </div>
    );
  };

  RenderEntries = () => {
    const {entries} = this.state.week;
    return entries.map (e => {
      return (
        <div className="row entry-row py-3" key={e._id}>
          <div className="col-md-2">
            <h5>
              {`${moment.utc (e.date).format ('YYYY-MM-DD')}`}
            </h5>
          </div>
          <div className="col-md-1">
            <p>
              {`${e.duration} ${e.duration > 1 ? 'hrs' : 'hr'}`}
            </p>
          </div>
          <div className="col-md-4">
            <p>
              {`${e.fiscalCode.code_program.prog_code} | ${e.fiscalCode.code_program.prog_description}`}
            </p>
          </div>
          <div className="col-md-4">
            <p>
              {`${e.fiscalCode.code_id} | ${e.fiscalCode.code_description}`}
            </p>
          </div>
          {!isEmpty (e.comments) &&
            <div className="col-md-1 ">
              <Tippy
                content={<span>{e.comments}</span>}
                placement="top-end"
                boundary="viewport"
              >
                <i className="fas fa-comment-dots fa-2x" />
              </Tippy>
            </div>}

        </div>
      );
    });
  };

  render () {
    return (
      <div className="container admin-entry">
        {this.state.redirect && <Redirect to="/admin/sommaire" />}
        {this.RenderHeader ()}
        {this.RenderTotal ()}
        {this.RenderEntries ()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  employees: state.employees,
  entry: state.entry,
  weeks: state.weeks,
});

const mapDispatchToProps = {GetEmployee, GetAllWeeks};

export default connect (mapStateToProps, mapDispatchToProps) (AdminEntryView);
