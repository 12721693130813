import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import roles from '../../enums/roles';
import {
  ArchiveEmployee,
  UnArchiveEmployee,
} from '../../store/actions/employeesActions';
import NewEmployeeModal from './NewEmployeeModal';

import classnames from 'classnames';

class EmployeesList extends Component {
  constructor (props) {
    super (props);
    this.state = {
      modal: '',
    };
  }

  GetEmployee = id => {
    return this.props.employeesList.filter (employee => {
      return employee._id === id;
    });
  };

  ShowModal = employe => {
    const modal = (
      <NewEmployeeModal
        ClearModal={this.ClearModal}
        retrievedState={employe}
        update={true}
      />
    );
    this.setState ({modal}, () => {
      document.getElementById ('modalEmployee-btn').click ();
    });
  };

  ClearModal = () => {
    document.getElementById ('modalEmployee-btn').click ();
    this.setState ({modal: ''});
  };

  HandleClick = e => {
    this.ShowModal (this.GetEmployee (e.currentTarget.dataset.id)[0]);
  };

  HandleArchive = e => {
    this.props.ArchiveEmployee (e.currentTarget.dataset.id);
  };
  HandleUnArchive = e => {
    this.props.UnArchiveEmployee (e.currentTarget.dataset.id);
  };
  render () {
    const activeEmployees = this.props.employeesList.filter (employee => {
      return employee.status === 'ACTIVE';
    });

    const archivedEmployees = this.props.employeesList.filter (employee => {
      return employee.status === 'ARCHIVED';
    });

    const employeesShowingList = activeEmployees.map (employee => {
      return (
        <div className="employees-list my-3" key={employee._id}>
          <div className="row">
            <div className="col">
              <span className="user-name text-align-left">{`${employee.firstName} ${employee.lastName}`}</span>
              {'  '}
              <span className="user-role">{`${roles.get (employee.role).name}`}</span>
            </div>
          </div>
          <div className="row codes-list">
            <div className="col-md-1 pt-2">{employee.code}</div>

            <div className="col-md-4 pt-2">{`${employee.email}`}</div>
            <div className="col-md-3 pt-2">{`${employee.contract && employee.contract.title}`}</div>
            <div
              className={classnames ('col pt-2', {
                'alert-color': employee.contract == null,
              })}
            >
              {employee.contract == null
                ? "Mettre à jour le contrat de l'employé(e)"
                : null}
            </div>
            <div data-id={employee._id} className="ml-auto btn btn-reseau">
              <i className="fas fa-chart-bar" />
            </div>
            <div
              data-id={employee._id}
              className="ml-auto btn btn-reseau"
              onClick={this.HandleClick}
            >
              <i className="fas fa-id-card" />
            </div>
            <div
              data-id={employee._id}
              className="ml-auto btn btn-reseau"
              onClick={employee.canBeDeleted ? this.HandleArchive : undefined}
              title={
                employee.canBeDeleted ? undefined : 'Ne peut pas être archivé'
              }
            >
              <i
                className={classnames ('fas fa-archive', {
                  disabled: !employee.canBeDeleted,
                })}
              />
            </div>
          </div>
        </div>
      );
    });

    const archiveList = archivedEmployees.map (employee => {
      return (
        <div className="archive-list row my-3" key={employee._id}>
          <div className="col-md-12">
            <h4 className="text-align-left">{`${employee.firstName} ${employee.lastName}`}</h4>
            <div className="row codes-list">
              <div className="col-md-2 pt-2">{employee.code}</div>
              <div className="col pt-2">{`${employee.email}`}</div>
              <div className="col pt-2">{`${roles.get (employee.role).name}`}</div>
              <div
                className={classnames ('col pt-2', {
                  'alert-color': employee.contract == null,
                })}
              >
                {employee.contract == null
                  ? "Mettre à jour le contrat de l'employé(e)"
                  : null}
              </div>
              <div className="ml-auto btn btn-reseau">
                <i className="fas fa-chart-bar" />
              </div>
              <div className="ml-auto btn btn-reseau">
                <i className="fas fa-id-card" />
              </div>
              <div
                data-id={employee._id}
                className="ml-auto btn btn-reseau"
                onClick={
                  employee.canBeDeleted ? this.HandleUnArchive : undefined
                }
                title={
                  employee.canBeDeleted ? undefined : 'Ne peut pas être archivé'
                }
              >
                <i
                  className={classnames ('fas fa-user-plus', {
                    disabled: !employee.canBeDeleted,
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      );
    });
    return (
      <div className="container">
        {this.state.modal}
        <div className="row my-3 py-1">
          <div className="col-md-12">
            <h3>Employés actifs</h3>
            {employeesShowingList}
          </div>
        </div>
        {archivedEmployees.length > 0 &&
          <div className="py-5">
            <h3>Employés archivés</h3>
            {archiveList}
          </div>}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  programs: state.programs,
});

export default connect (mapStateToProps, {ArchiveEmployee, UnArchiveEmployee}) (
  EmployeesList
);
