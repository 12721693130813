// import action
import {
  ADD_EMPLOYEE,
  GET_CURRENT_EMPLOYEE,
  GET_ALL_EMPLOYEES,
} from '../actions/types';

const initialState = {
  employeesList: [],
  selectedEmployee: {},
};

const employeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_EMPLOYEES:
      return {...state, employeesList: action.payload};
    case GET_CURRENT_EMPLOYEE:
      return {...state, selectedEmployee: action.payload};
    case ADD_EMPLOYEE:
      return state;
    default:
      return state;
  }
};

export default employeesReducer;
