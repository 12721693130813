import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';
//ACTIONS
import {
  AddEmployee,
  UpdateEmployee,
} from '../../store/actions/employeesActions';
import {GetAllRegions} from '../../store/actions/regionsActions';

//ENUMS
import roles from '../../enums/roles';
import isEmpty from '../../validation/isEmpty';

//HELPER CLASSES
//import isEmpty from '../../validation/isEmpty';

class NewEmployeeModal extends Component {
  constructor (props) {
    super (props);
    this.state = {
      code: '',
      role: 3,
      region: 'RT',
      email: '',
      pwd: '',
      employementDate: moment ().utc ().format ('YYYY-MM-DD'),
      employementDateEnd: '',
      firstName: '',
      lastName: '',
      canBeDeleted: true,
      isSalaryAnnual: false,
      contract: {
        title: '',
        hourPerWeek: 35,
        hourRate: 15.50,
        reerRate: 1,
        insuranceType: 1,
        vacationsDaysPerYear: 10,
        sickDaysPerYear: 5,
      },
      contact: {
        phone: '',
        address: '',
        city: '',
        postalCode: '',
        emergency_contact: '',
        emergency_phone: '',
      },
      hoursUsed: {
        vacation: 0,
        insuranceLeave: 0,
        statutoryHoliday: 0,
        sickLeave: 0,
        overtime: 0,
        socialLeave: 0,
      },
      workedWeeks: [],
    };
  }

  componentDidMount = () => {
    this.props.GetAllRegions ();
    //FILLS WITH USER INFO
    if (Boolean (this.props.retrievedState)) {
      let newState = {...this.state, ...this.props.retrievedState, pwd: ''};
      this.setState (newState);
    }
  };

  AddEmployee = () => {
    this.props.AddEmployee (this.state);
    this.props.ClearModal ();
  };

  UpdateEmployee = () => {
    const employee = this.state;
    if (isEmpty (employee.pwd)) {
      delete employee.pwd;
    }

    this.props.UpdateEmployee (employee);
    this.props.ClearModal ();
  };

  FormatPostalCode = string => {
    let postalCode = /(\w{1}\d{1}\w{1})\s?(\d{1}\w{1}\d{1})/;
    let postalCodeFormatted = `$1 $2`;
    let formattedString = string
      .replace (postalCode, postalCodeFormatted)
      .toUpperCase ();
    return formattedString;
  };
  FormatPhone = string => {
    let phoneRegExp = /\(?(\d{3})\)?\s?(\d{3})(\s|-)?(\d{4})/;
    let phoneFormatted = '($1) $2-$4';
    let formattedString = string.replace (phoneRegExp, phoneFormatted);
    return formattedString;
  };

  HandleSelect = e => {
    this.setState ({[e.currentTarget.name]: e.currentTarget.value});
  };

  HandleCheck = e => {
    this.setState ({[e.currentTarget.name]: e.currentTarget.checked});
  };

  HandleText = e => {
    this.setState ({[e.currentTarget.name]: e.currentTarget.value});
  };

  HandleDate = e => {
    let val = moment.utc (e.currentTarget.value).isValid ()
      ? moment.utc (e.currentTarget.value).format ('YYYY-MM-DD')
      : '';
    this.setState ({
      [e.currentTarget.name]: val,
    });
  };

  HandleEmail = e => {
    let email = e.currentTarget.value;
    this.setState ({[e.currentTarget.name]: email.toLowerCase ()});
  };

  HandleContractText = e => {
    let contract = this.state.contract;
    contract[e.currentTarget.name] = e.currentTarget.value;

    this.setState ({contract});
  };

  HandleContractNumber = e => {
    let contract = this.state.contract;
    contract[e.currentTarget.name] = parseFloat (
      e.currentTarget.value
    ).toFixed (2);

    this.setState ({contract});
  };

  HandleContactText = e => {
    let contact = this.state.contact;
    contact[e.currentTarget.name] = e.currentTarget.value;

    this.setState ({contact});
  };
  HandleContactPostalCode = e => {
    let contact = this.state.contact;
    let formattedString = this.FormatPostalCode (e.currentTarget.value);
    contact[e.currentTarget.name] = formattedString;

    this.setState ({contact});
  };
  HandleContactPhone = e => {
    let contact = this.state.contact;
    let formattedString = this.FormatPhone (e.currentTarget.value);
    contact[e.currentTarget.name] = formattedString;

    this.setState ({contact});
  };

  render () {
    const {employementDate, employementDateEnd} = this.state;
    const isInvalid = false;
    const isUpdating = this.props.update;
    const {regions} = this.props.regions;
    const regionsOpts = regions.map (region => {
      return (
        <option key={region._id} value={region._id}>{region.longName}</option>
      );
    });
    const rolesOpts = roles.values.map (role => {
      return <option key={role.id} value={role.id}>{role.name}</option>;
    });
    return (
      <Fragment>
        <button
          type="button"
          className="btn btn-primary d-none"
          data-toggle="modal"
          data-target="#modalNewProgram"
          id="modalEmployee-btn"
        />
        <div
          className="modal fade"
          id="modalNewProgram"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modalEmployeeLabel"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content ">
              <div className="modal-header">
                <h3 className="modal-title text-center" id="modalEmployeeLabel">
                  Nouvel(lle) employé(e)
                </h3>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="closeModalBtn"
                  onClick={this.props.ClearModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <hr />
              <div className="modal-body" id="modalEmployee-body">
                <form onSubmit={this.AddEmployee}>
                  {/* PERSONAL INFOS */}
                  <h5>Informations personnelles</h5>
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <label htmlFor="code">Code d'employé</label>
                      <input
                        type="text"
                        className="form-control"
                        name="code"
                        id="code"
                        placeholder="Code d'employé"
                        value={this.state.code}
                        onChange={this.HandleText}
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="role">Rôle</label>
                      <select
                        className="custom-select"
                        name="role"
                        id="role"
                        value={this.state.role}
                        onChange={this.HandleSelect}
                      >
                        <option value="">Choisir un rôle</option>
                        {rolesOpts}
                      </select>
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="region">Organisme régional</label>
                      <select
                        className="custom-select"
                        name="region"
                        id="region"
                        value={this.state.region}
                        onChange={this.HandleSelect}
                      >
                        <option value="">Choisir un organisme régional</option>
                        {regionsOpts}
                      </select>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="firstName">Prénom</label>
                      <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        id="firstName"
                        placeholder="Prénom"
                        value={this.state.firstName}
                        onChange={this.HandleText}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="lastName">Nom</label>
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        id="lastName"
                        placeholder="Nom de famille"
                        value={this.state.lastName}
                        onChange={this.HandleText}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="pwd">Mot de passe</label>
                      <input
                        type="password"
                        className="form-control"
                        name="pwd"
                        id="pwd"
                        placeholder="Mot de passe"
                        value={this.state.pwd}
                        onChange={this.HandleText}
                      />
                    </div>

                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="canBeDeleted"
                          name="canBeDeleted"
                          checked={this.state.canBeDeleted}
                          onChange={this.HandleCheck}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="canBeDeleted"
                        >
                          Peut être archivé
                        </label>
                      </div>
                    </div>
                    <div className="form-group col-md-4">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="isSalaryAnnual"
                          name="isSalaryAnnual"
                          checked={this.state.isSalaryAnnual}
                          onChange={this.HandleCheck}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="isSalaryAnnual"
                        >
                          Employé annuel
                        </label>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="employementDate">Date d'embauche</label>
                      <input
                        type="date"
                        className="form-control"
                        name="employementDate"
                        id="employementDate"
                        value={
                          moment.utc (employementDate).isValid ()
                            ? moment.utc (employementDate).format ('YYYY-MM-DD')
                            : ''
                        }
                        onChange={this.HandleDate}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="employementDateEnd">
                        Date de fin d'emploi
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        name="employementDateEnd"
                        id="employementDateEnd"
                        value={
                          moment.utc (employementDateEnd).isValid ()
                            ? moment
                                .utc (employementDateEnd)
                                .format ('YYYY-MM-DD')
                            : ''
                        }
                        onChange={this.HandleDate}
                      />
                    </div>
                  </div>
                  {/* PERSONAL INFOS END*/}
                  <hr />

                  {/* CONTACT INFOS */}
                  <h5>Informations</h5>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="phone">Téléphone</label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        id="phone"
                        placeholder="(514) 222-2222"
                        value={this.state.contact.phone}
                        onChange={this.HandleContactPhone}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="email">Courriel</label>
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Courriel"
                        value={this.state.email}
                        onChange={this.HandleEmail}
                        readOnly={isUpdating}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <label htmlFor="address">Adresse</label>
                      <input
                        type="text"
                        className="form-control"
                        name="address"
                        id="address"
                        placeholder="4545, Pierre-De Coubertin, app. 4"
                        value={this.state.contact.address}
                        onChange={this.HandleContactText}
                      />
                    </div>
                    <div className="form-group col-md-8">
                      <label htmlFor="city">Ville</label>
                      <input
                        type="text"
                        className="form-control"
                        name="city"
                        id="city"
                        placeholder="Montréal"
                        value={this.state.contact.city}
                        onChange={this.HandleContactText}
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="city">Code postal</label>
                      <input
                        type="text"
                        className="form-control"
                        name="postalCode"
                        id="postalCode"
                        placeholder="H1V 0B2"
                        value={this.state.contact.postalCode}
                        onChange={this.HandleContactPostalCode}
                      />
                    </div>
                  </div>
                  {/* CONTACT INFOS END*/}
                  <hr />
                  {/* CONTACT INFOS */}
                  <h5>Urgence</h5>
                  <div className="form-row">
                    <div className="form-group col-md-8">
                      <label htmlFor="emergency_contact">
                        Personne en cas d'urgence
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="emergency_contact"
                        id="emergency_contact"
                        value={this.state.contact.emergency_contact}
                        onChange={this.HandleContactText}
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="emergency_phone">
                        Téléphone d'urgence
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="emergency_phone"
                        id="emergency_phone"
                        placeholder="(514) 222-2222"
                        value={this.state.contact.emergency_phone}
                        onChange={this.HandleContactPhone}
                      />
                    </div>
                  </div>
                  {/* CONTACT INFOS END*/}
                  <hr />
                  {/* CONTRACT INFOS */}
                  <h5>Contrat</h5>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="title">Titre d'emploi</label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        id="title"
                        placeholder="Titre"
                        value={this.state.contract.title}
                        onChange={this.HandleContractText}
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="hourPerWeek">
                        Nb d'heures par semaine
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="hourPerWeek"
                        id="hourPerWeek"
                        min="0"
                        placeholder="35"
                        value={this.state.contract.hourPerWeek}
                        onChange={this.HandleContractText}
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="hourRate">Taux horaire</label>
                      <input
                        type="number"
                        className="form-control"
                        name="hourRate"
                        id="hourRate"
                        step="0.01"
                        min="0"
                        placeholder="15.50"
                        value={this.state.contract.hourRate}
                        onChange={this.HandleContractNumber}
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="reerRate">Taux du reer collectif</label>
                      <input
                        type="number"
                        className="form-control"
                        name="reerRate"
                        id="reerRate"
                        min="0"
                        placeholder="1"
                        value={this.state.contract.reerRate}
                        onChange={this.HandleContractText}
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="insuranceType">Plan assurances</label>
                      <input
                        type="number"
                        className="form-control"
                        name="insuranceType"
                        id="insuranceType"
                        min="1"
                        max="3"
                        placeholder="1"
                        value={this.state.contract.insuranceType}
                        onChange={this.HandleContractText}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="vacationsDaysPerYear">
                        Jours de vacances annuels
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="vacationsDaysPerYear"
                        id="vacationsDaysPerYear"
                        min="0"
                        placeholder="10"
                        value={this.state.contract.vacationsDaysPerYear}
                        onChange={this.HandleContractText}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="sickDaysPerYear">
                        Jours maladies annuels
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="sickDaysPerYear"
                        id="sickDaysPerYear"
                        min="0"
                        placeholder="5"
                        value={this.state.contract.sickDaysPerYear}
                        onChange={this.HandleContractText}
                      />
                    </div>
                  </div>
                  {/* CONTRACT INFOS END*/}
                </form>
              </div>
              <div className="modal-footer">
                {!this.props.update &&
                  <button
                    type="button"
                    className={classnames ('btn btn-secondary', {
                      'btn-disabled': isInvalid,
                    })}
                    onClick={this.AddEmployee}
                  >
                    Ajouter
                  </button>}
                {this.props.update &&
                  <button
                    type="button"
                    className={classnames ('btn btn-secondary', {
                      'btn-disabled': isInvalid,
                    })}
                    onClick={this.UpdateEmployee}
                  >
                    Mettre à jour
                  </button>}
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={this.props.ClearModal}
                >
                  Annuler
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  programs: state.programs,
  regions: state.regions,
});

export default connect (mapStateToProps, {
  AddEmployee,
  UpdateEmployee,
  GetAllRegions,
}) (NewEmployeeModal);
