import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {GetAllWeeks, ChangeWeekStatus} from '../../store/actions/weeksActions';
import {GetEmployee} from '../../store/actions/employeesActions';
import DateHelpers from '../../utils/DateHelpers';
import isEmpty from '../../validation/isEmpty';
import * as moment from 'moment-timezone';
import classnames from 'classnames';

moment.tz ('America/Toronto').format ('Z');
moment.locale ('fr');
moment.updateLocale ('fr', {
  week: {
    dow: 0,
  },
});
export class ViewAllWeeks extends Component {
  constructor (props) {
    super (props);

    this.state = {
      year: DateHelpers.getFiscalYear (moment ().utc ()),
      weeks: [],
    };
  }

  componentDidMount () {
    const {id} = this.props.match.params;
    this.props.GetEmployee (id);
  }
  componentDidUpdate (prevProps, prevState) {
    if (prevProps.employees !== this.props.employees) {
      if (this.props.employees.selectedEmployee) {
        this.props.GetAllWeeks (
          this.state.year,
          this.props.employees.selectedEmployee._id
        );
      }
    }
  }

  HandleStatusSelect = e => {
    this.props.ChangeWeekStatus (e.currentTarget.name, e.currentTarget.value);
  };

  RenderHeader = () => {
    const {selectedEmployee} = this.props.employees;
    return (
      <div className="row my-3 py-1">
        <div className="col-md-12">
          <h1>Liste des semaines travaillées</h1>
          <h4
          >{`${selectedEmployee.firstName} ${selectedEmployee.lastName}`}</h4>
        </div>
      </div>
    );
  };

  RenderEmployeeSelect = () => {
    return (
      <div>
        <h1>test</h1>
      </div>
    );
  };

  RenderWeeks = () => {
    if (isEmpty (this.props.employees.selectedEmployee)) return;
    if (isEmpty (this.props.weeks) || isEmpty (this.props.weeks.weeks)) {
      return (
        <div className="row my-3">
          <div className="col">
            <h3>Aucune feuille de temps saisie</h3>
          </div>
        </div>
      );
    }
    const {hourPerWeek} = this.props.employees.selectedEmployee.contract;

    let weeks = this.props.weeks.weeks;
    weeks = weeks.sort ((a, b) => {
      return a.period < b.period;
    });

    return weeks.map (w => {
      const totalWorked = w.entries.reduce ((acc, current) => {
        return acc + current.duration;
      }, 0);

      return (
        <div key={w._id} className="time-row row my-3 my-md-1" data-id={w._id}>
          <div className="col-md-4 ">
            <p
            >{`Période ${w.period} | Du ${moment
              .utc (w.weekStart)
              .format (
                'YYYY-MM-DD'
              )} au ${moment.utc (w.weekEnd).format ('YYYY-MM-DD')}`}</p>
          </div>
          <div
            className={classnames (
              'col-md-2',
              {
                'overtime-alert': totalWorked > hourPerWeek,
              },
              {
                'undertime-alert': totalWorked < hourPerWeek,
              }
            )}
          >
            <h3>{`${totalWorked} / ${hourPerWeek} heures`}</h3>
            {totalWorked > hourPerWeek &&
              <small>
                +
                {' '}
                {totalWorked - hourPerWeek}
                {' '}
                heure
                {totalWorked - hourPerWeek > 1 && 's'}
              </small>}
            {totalWorked < hourPerWeek &&
              <small>
                -
                {' '}
                {hourPerWeek - totalWorked}
                {' '}
                heure
                {hourPerWeek - totalWorked > 1 && 's'}
              </small>}
          </div>
          <div className="col">
            {this.RenderStatusSelect (w._id, w.status)}
          </div>
          <div className="col">
            <Link
              to={`/admin/entrees/${this.props.employees.selectedEmployee._id}?period=${w.period}&year=${w.fiscalYear}`}
            >
              <i className="fas fa-eye fa-2x pt-3" />
            </Link>
          </div>
        </div>
      );
    });

    //AFFICHER SEMAINE + overtime
  };

  RenderReturnButton = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <Link to="/admin/sommaire">Retour à la liste des employés</Link>
        </div>
      </div>
    );
  };

  RenderStatusSelect = (id, status) => {
    return (
      <div className="form-group">
        <label htmlFor={id}>Status</label>
        <select
          className="form-control"
          name={id}
          id={id}
          onChange={this.HandleStatusSelect}
          defaultValue={status}
        >
          <option value="UNSENT">Non envoyée pour approbation</option>
          <option value="CONDITIONAL">Doit être modifiée</option>
          <option value="PENDING">En attente d'approbation</option>
          <option value="APPROVED">Semaine approuvée</option>
          <option value="DENIED">Semaine refusée</option>
        </select>
      </div>
    );
  };

  render () {
    return (
      <div>
        {this.RenderHeader ()}
        {this.RenderReturnButton ()}
        {this.RenderWeeks ()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  employees: state.employees,
  weeks: state.weeks,
});

const mapDispatchToProps = {GetEmployee, GetAllWeeks, ChangeWeekStatus};

export default connect (mapStateToProps, mapDispatchToProps) (ViewAllWeeks);
