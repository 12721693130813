import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {logoutUser} from '../../../store/actions/authActions';
import {GetCurrentEmployee} from '../../../store/actions/employeesActions';

import ROLES from '../../../enums/roles';

class Nav extends Component {
  constructor (props) {
    super (props);
    this.onLogout = this.onLogout.bind (this);
  }
  componentDidMount () {
    this.props.GetCurrentEmployee (this.props.auth.utilisateur.id);
  }

  onLogout () {
    this.props.logoutUser ();
  }
  render () {
    const isAuthenticated = this.props.auth.isAuthenticated;
    const isAdmin = this.props.auth.utilisateur.role === ROLES.ADMIN;
    const role = ROLES.get (this.props.auth.utilisateur.role);
    const {isSalaryAnnual} = this.props.auth.utilisateur;

    return (
      <nav className="navbar navbar-expand-md">
        <a className="navbar-brand" href="/">
          <img
            src="img/LogoTechnoscience_sansSlogan_Coul.png"
            alt="Logo"
            height="100px"
          />
        </a>

        {/*BOUTON MOBILE*/}
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#menuPrincipal"
          aria-controls="menuPrincipal"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        {/* MENU PRINCIPAL*/}
        <div className="collapse navbar-collapse" id="menuPrincipal">
          <ul className="navbar-nav mr-auto">
            {isAuthenticated &&
              <ul className="nav navbar-nav">
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="menuSemaines"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Semaines de travail
                  </Link>
                  <div className="dropdown-menu" aria-labelledby="menuSemaines">
                    <Link className="dropdown-item" to="/">
                      Ajouter une entrée de temps
                    </Link>
                    <Link className="dropdown-item" to="/sommaire">
                      Voir l'ensemble des semaines
                    </Link>
                    {!isSalaryAnnual &&
                      <Link
                        className="dropdown-item"
                        to="/temps-supplementaire"
                      >
                        Voir banque de temps supplémentaire
                      </Link>}

                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="menuProfil"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Profil
                  </Link>
                  <div className="dropdown-menu" aria-labelledby="menuProfil">
                    <Link className="dropdown-item" to="/profil">
                      Voir le profil
                    </Link>
                    <Link className="dropdown-item" to="/urgence">
                      Modifier les informations d'urgence
                    </Link>

                  </div>
                </li>
                {isAdmin &&
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to="#"
                      id="menuProfil"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Admin
                    </Link>
                    <div className="dropdown-menu" aria-labelledby="menuProfil">
                      <Link className="dropdown-item" to="/admin/sommaire">
                        Sommaire des entrées de temps
                      </Link>
                      <Link className="dropdown-item" to="/admin/a-approuver">
                        Semaines à approuver
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/admin/indices-comptables"
                      >
                        Programmes et indices comptables
                      </Link>
                      <Link className="dropdown-item" to="/admin/utilisateurs">
                        Gestion des utilisateurs
                      </Link>
                      <Link className="dropdown-item" to="/admin/exportation">
                        Exportation des données
                      </Link>
                    </div>
                  </li>}
              </ul>}
          </ul>
          <div className="pull-right">
            <ul className="nav navbar-nav">
              {isAuthenticated
                ? <li className="nav-item text-right">
                    <Link className="nav-link" to="#" onClick={this.onLogout}>
                      Déconnexion
                    </Link>
                    <small className="p-2">
                      {this.props.auth.utilisateur.email} - {role.name}
                    </small>
                  </li>
                : <li className="nav-item">
                    <Link className="nav-link" to="/connexion">
                      Connexion
                    </Link>
                  </li>}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
const mapStateToProps = state => ({
  employees: state.employees,
  auth: state.auth,
});
export default connect (mapStateToProps, {logoutUser, GetCurrentEmployee}) (
  Nav
);
