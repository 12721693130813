import React, {Component} from 'react';
import {connect} from 'react-redux';
import XLSX from 'xlsx';
import DateHelpers from '../../../utils/DateHelpers';
import {
  ExportAllWeeksByDate,
  ExportAllWeeksByPeriod,
  SetSelectedWeek,
} from '../../../store/actions/weeksActions';
import {GetAllEmployees} from '../../../store/actions/employeesActions';

import * as moment from 'moment-timezone';

moment.locale ('fr');
moment.updateLocale ('fr', {
  week: {
    dow: 0,
  },
});
moment.tz ('America/Toronto').format ('Z');


const MAX_HOURS_PER_WEEK = 40;

export class ExportData extends Component {
  constructor (props) {
    super (props);

    this.state = {
      rangeStart: moment.utc ().format ('YYYY-MM-DD'),
      rangeEnd: moment.utc ().format ('YYYY-MM-DD'),
      year: DateHelpers.getFiscalYear (moment ().utc ()),
      period: DateHelpers.getPeriodNumber (moment ().utc ()),
      selectedEmployees: [],
      isDownloaded: false,
      view: 'period',
    };
  }

  componentDidMount () {
    this.props.GetAllEmployees ();
    const rangeStart = moment ()
      .utc ()
      .year (parseInt (this.state.year))
      .month (8)
      .date (1)
      .day (0)
      .format ('YYYY-MM-DD');
    const rangeEnd = moment ()
      .utc ()
      .year (parseInt (this.state.year))
      .month (8)
      .date (1)
      .day (0)
      .add (1, 'year')
      .subtract (1, 'day')
      .format ('YYYY-MM-DD');
    this.setState ({rangeStart, rangeEnd});
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.weeks !== this.props.weeks) {
      if (
        prevProps.weeks.weeks !== this.props.weeks.weeks &&
        !this.state.isDownloaded
      ) {
        this.ExportData ();
      }
    }
  }

  FetchData = e => {
    const {
      rangeStart,
      rangeEnd,
      year,
      period,
      view,
      selectedEmployees,
    } = this.state;
    if (view === 'range') {
      this.props.ExportAllWeeksByDate (rangeStart, rangeEnd, selectedEmployees);
    } else if (view === 'period') {
      this.props.ExportAllWeeksByPeriod (year, period, selectedEmployees);
    }
  };

  GetWeekList = year => {
    let start = moment ().year (parseInt (year)).month (8).date (1).day (0);

    let end = moment ().year (parseInt (year) + 1).month (8).date (1).day (0);

    var arr = [];
    // Get sunday
    let tmp = start.clone ();

    while (tmp.isBefore (end, 'd')) {
      if (tmp.isSameOrAfter (end, 'd')) break;
      arr.push (tmp.format ('YYYY-MM-DD'));
      tmp.add (7, 'days');
    }
    return arr;
  };

  FindSelectedWeek = week => {
    const {period} = this.state;
    return week.period.toString () === period.toString ();
  };

  GetStatusLabel = status => {
    let label = '';
    switch (status) {
      case 'CONDITIONAL':
        label = 'Semaine à modifier';
        break;
      case 'PENDING':
        label = "Semaine en attente d'approbation";
        break;
      case 'APPROVED':
        label = 'Semaine approuvée';
        break;
      case 'DENIED':
        label = 'Semaine refusée';
        break;
      case 'UNSENT':
      default:
        label = 'Semaine non envoyée pour approbation';
        break;
    }
    return label;
  };

  ExportData = () => {
    this.setState ({isDownloaded: true});
    const {weeks} = this.props.weeks;
    const formattedData = this.FormatData (weeks);
    const file = this.ObjectToXLSX (formattedData);
    this.Download (file, formattedData.name);
    this.setState ({isDownloaded: false});
  };

  CalcWeeksTotal = (acc, entry) => {
    return acc + parseFloat (entry.duration);
  };

  CalcUsedOvertimePerPeriod = entries => {
    const overtimeUsed = entries
      .filter (e => {
        return (
          e.fiscalCode.code_program.prog_code === 'Z' &&
          e.fiscalCode.code_id === '999'
        );
      })
      .reduce ((acc, entry) => {
        return acc + parseFloat (entry.duration);
      }, 0);

    return overtimeUsed;
  };

  CalculateOvertime = (totalHours, expectedHours) => {
    if (expectedHours > totalHours) return totalHours - expectedHours;

    const hoursBeforeMax = totalHours > MAX_HOURS_PER_WEEK
      ? MAX_HOURS_PER_WEEK - expectedHours
      : totalHours - expectedHours;
    const hoursAfterMax = totalHours > MAX_HOURS_PER_WEEK
      ? (totalHours - MAX_HOURS_PER_WEEK) * 1.5
      : 0;
    return hoursBeforeMax + hoursAfterMax;
  };

  FlattenObject = ob => {
    var toReturn = {};

    for (var i in ob) {
      if (!ob.hasOwnProperty (i)) continue;

      if (typeof ob[i] == 'object') {
        var flatObject = this.FlattenObject (ob[i]);
        for (var x in flatObject) {
          if (!flatObject.hasOwnProperty (x)) continue;

          toReturn[i + ' ' + x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }
    return toReturn;
  };

  FormatData = list => {
    const data = {};
    data.name = 'Rapport feuilles de temps';
    data.content = list.map (week => {
      const {
        employeId,
        entries,
        period,
        fiscalYear,
        status,
        weekStart,
        weekEnd,
      } = week;
      const {firstName, lastName, contract, code} = employeId;
      const {title, hourPerWeek} = contract;
      entries.sort ((a, b) => {
        return moment.utc (a.date).isAfter (b.date, 'day');
      });
      const totalPerWeek = entries.reduce (this.CalcWeeksTotal, 0);
      const overtimePerWeek = this.CalculateOvertime (
        totalPerWeek,
        hourPerWeek
      );
      const usedOvertimePerWeek = this.CalcUsedOvertimePerPeriod (entries);

      return entries.map (entry => {
        const {date, duration, comments, fiscalCode} = entry;
        const {code_description, code_id, code_program} = fiscalCode;
        const {prog_code, prog_description} = code_program;
        const newElem = {
          employe_id: code,
          prenom: firstName,
          nom_famille: lastName,
          contrat_titre: title,
          contrat_heures_semaine: hourPerWeek,
          annee_financiere: fiscalYear,
          periode_paie: period,
          semaine_debut: moment.utc (weekStart).format ('YYYY-MM-DD 02:00:00'),
          semaine_fin: moment.utc (weekEnd).format ('YYYY-MM-DD 02:00:00'),
          semaine_status: this.GetStatusLabel (status),
          date: moment.utc (date).format ('YYYY-MM-DD 02:00:00'),
          programme_id: prog_code,
          code_temps: code_id,
          programme_description: prog_description,
          code_description: code_description,
          duree: duration,
          commentaires: comments,
          total_travaille_par_periode: totalPerWeek,
          temps_accumule_par_periode: overtimePerWeek,
          heures_reprises_par_periode: usedOvertimePerWeek,
        };
        return newElem;
      });
    });
    data.content = data.content.flat (2);
    return data;
  };

  ObjectToXLSX = data => {
    //CREATE WORKBOOK
    const wb = XLSX.utils.book_new ();
    wb.Props = {
      Title: data.name,
      Subject: 'Rapport de feuilles de temps',
      Author: 'Réseau Technoscience',
      CreatedDate: moment.utc ().format ('YYYY-MM-DD'),
    };
    //CREATE WORKSHEET
    wb.SheetNames.push (data.name);
    const header = [
      'employe_id',
      'prenom',
      'nom_famille',
      'contrat_titre',
      'contrat_heures_semaine',
      'annee_financiere',
      'periode_paie',
      'semaine_debut',
      'semaine_fin',
      'semaine_status',
      'date',
      'programme_id',
      'code_temps',
      'programme_description',
      'code_description',
      'duree',
      'commentaires',
      'total_travaille_par_periode',
      'temps_accumule_par_periode',
      'heures_reprises_par_periode',
    ];
    //ADD DATA TO SHEET
    const ws = XLSX.utils.json_to_sheet (data.content, {header});

    //SET columns to date type
    var range = XLSX.utils.decode_range (ws['!ref']);
    for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
      const semaineDebutCell = ws[XLSX.utils.encode_cell ({r: rowNum, c: 7})];
      const semaineFinCell = ws[XLSX.utils.encode_cell ({r: rowNum, c: 8})];
      const dateCell = ws[XLSX.utils.encode_cell ({r: rowNum, c: 10})];
      if (rowNum > 0) {
        semaineDebutCell.t = 'd';
        semaineFinCell.t = 'd';
        dateCell.t = 'd';
      }
    }

    //INCLUDE WORKSHEET TO WORKBOOK
    wb.Sheets[data.name] = ws;

    //CREATE FILE
    const wbout = XLSX.write (wb, {bookType: 'xlsx', type: 'binary'});

    //CONVERT TO BINARY TO SEND
    const buf = new ArrayBuffer (wbout.length);
    const view = new Uint8Array (buf);
    for (let i = 0; i < wbout.length; i++) {
      view[i] = wbout.charCodeAt (i) & 0xff;
    }
    return buf;
  };

  Download = (file, name) => {
    const blob = new Blob ([file], {type: 'application/octet-stream'});
    const url = window.URL.createObjectURL (blob);
    const a = document.createElement ('a');
    a.setAttribute ('hidden', '');
    a.setAttribute ('href', url);
    a.setAttribute ('download', name + '.xlsx');
    document.body.appendChild (a);
    a.click ();
    document.body.removeChild (a);
  };

  OnHandleChange = e => {
    const state = this.state;
    state[e.currentTarget.name] = e.currentTarget.value;
    this.setState (state);
  };

  OnHandleMultipleSelect = e => {
    const selectedEmployees = [...e.currentTarget.selectedOptions].map (e => {
      return e.value;
    });
    this.setState ({selectedEmployees});
  };

  OnHandleViewChange = e => {
    this.setState ({view: e.currentTarget.value});
  };
  OnWeekChange = e => {
    const state = this.state;
    state.period = e.currentTarget.value;
    state.sunday = e.currentTarget.selectedOptions[0].dataset.sunday;
    this.setState (state);
  };

  OnYearChange = e => {
    const state = this.state;
    state.year = e.currentTarget.value;
    this.setState (state);
  };

  RenderHeader = () => {
    return (
      <div className="row my-3 py-1">
        <div className="col-md-12">
          <h1>
            Exportation des feuilles de temps
          </h1>
        </div>
      </div>
    );
  };

  RenderDateSelect = () => {
    const {rangeStart, rangeEnd, view} = this.state;
    if (view !== 'range') return;
    return (
      <div className="row py-2">
        <div className="col-md-12">
          <h4>Exportation par dates</h4>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="rangeStart">
              <h5>
                Début de la période
              </h5>
            </label>
            <input
              type="date"
              className="form-control"
              name="rangeStart"
              id="rangeStart"
              value={rangeStart}
              onChange={this.OnHandleChange}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="rangeEnd">
              <h5>
                Fin de la période
              </h5>
            </label>
            <input
              type="date"
              className="form-control"
              name="rangeEnd"
              id="rangeEnd"
              value={rangeEnd}
              onChange={this.OnHandleChange}
            />
          </div>
        </div>
      </div>
    );
  };
  RenderPeriodSelect = () => {
    return (
      <div className="row py-2 week-page">
        <div className="col-md-12">
          <h4>Exportation par période de paie</h4>
        </div>
        <div className="col-md-6">
          {this.YearSelect ()}
        </div>
        <div className="col-md-6">
          {this.WeekSelect ()}
        </div>
      </div>
    );
  };

  YearSelect = () => {
    return (
      <div className="form-group">
        <label htmlFor="year">
          <h5>
            Année fiscale
          </h5>
        </label>
        <select
          className="custom-select"
          name="year"
          id="year"
          onChange={this.OnYearChange}
          value={this.state.year}
        >
          {this.YearOptions ()}
        </select>
      </div>
    );
  };

  YearOptions = () => {
    const startYear = moment
      .utc ()
      .year (2018)
      .month (8)
      .day (1)
      .format ('YYYY');
    let actualDate = parseInt (moment ().format ('YYYY'));
    const options = [];

    do {
      const opt = (
        <option key={actualDate} value={actualDate}>
          {actualDate}-{actualDate + 1}
        </option>
      );
      options.push (opt);
      actualDate = parseInt (actualDate) - 1;
    } while (actualDate >= startYear);

    return options;
  };

  WeekSelect = () => {
    return (
      <div className="form-group">
        <label htmlFor="period">
          <h5>
            Semaine du
          </h5>
        </label>
        <select
          className="custom-select"
          name="period"
          id="period"
          onChange={this.OnWeekChange}
          value={this.state.period}
        >
          {this.WeekOptions ()}
        </select>
      </div>
    );
  };

  WeekOptions = () => {
    const {year} = this.state;
    const weekList = this.GetWeekList (year);
    return weekList.map (this.WeekOpt);
  };

  WeekOpt = (week, index) => {
    return (
      <option
        data-sunday={moment.utc (week).format ('YYYY-MM-DD')}
        value={DateHelpers.getPeriodNumber (
          moment.utc (week).format ('YYYY-MM-DD')
        )}
        key={index}
      >
        Période
        {' '}
        {DateHelpers.getPeriodNumber (moment.utc (week).format ('YYYY-MM-DD'))}
        {' '}
        |
        {' '}
        {moment.utc (week).format ('DD MMMM YYYY')}
      </option>
    );
  };

  KeepActiveOnly = (employee, index) => {
    return employee.status === 'ACTIVE';
  };

  RenderEmployeeSelect = () => {
    const opts = this.props.employees.employeesList
      .filter (this.KeepActiveOnly)
      .map (this.EmployeeOpts);

    return (
      <div className="form-group">
        <label htmlFor="selectedEmployee" />
        <select
          size="15"
          multiple
          className="custom-select"
          name="selectedEmployee"
          id="selectedEmployee"
          onChange={this.OnHandleMultipleSelect}
        >
          {opts}
        </select>
      </div>
    );
  };
  EmployeeOpts = (employe, index) => {
    return (
      <option
        key={index}
        value={employe._id}
      >{`${employe.firstName} ${employe.lastName}`}</option>
    );
  };
  RenderViewMode = () => {
    return (
      <div className="col-md-12">
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="view"
            id="period"
            value="period"
            checked={this.state.view === 'period'}
            onChange={this.OnHandleViewChange}
          />
          <label className="form-check-label" htmlFor="period">
            Vue par période
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="view"
            id="range"
            value="range"
            checked={this.state.view === 'range'}
            onChange={this.OnHandleViewChange}
          />
          <label className="form-check-label" htmlFor="range">
            Vue par dates
          </label>
        </div>
      </div>
    );
  };
  RenderBtn = () => {
    return (
      <div className="col-md-4 pt-4 text-center">
        <button className="btn btn-primary " onClick={this.FetchData}>
          <h4>
            Exporter l'ensemble des feuilles de temps par employé
          </h4>
        </button>
      </div>
    );
  };
  render () {
    const {view} = this.state;
    return (
      <div className="container">
        {this.RenderHeader ()}
        {this.RenderViewMode ()}
        {view === 'range' && this.RenderDateSelect ()}
        {view === 'period' && this.RenderPeriodSelect ()}
        {this.RenderEmployeeSelect ()}
        {this.RenderBtn ()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  weeks: state.weeks,
  employees: state.employees,
});

const mapDispatchToProps = {
  ExportAllWeeksByDate,
  ExportAllWeeksByPeriod,
  SetSelectedWeek,
  GetAllEmployees,
};

export default connect (mapStateToProps, mapDispatchToProps) (ExportData);
