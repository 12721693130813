import React, {Component} from 'react';
import {connect} from 'react-redux';
import {GetAllEmployees} from '../../../store/actions/employeesActions';
import EmployeesList from '../../users/EmployeesList';
import NewEmployeeModal from '../../users/NewEmployeeModal';
class Utilisateurs extends Component {
  constructor (props) {
    super (props);
    this.state = {
      modal: '',
    };
  }

  componentDidMount () {
    this.props.GetAllEmployees ();
  }

  ShowModal = () => {
    const modal = <NewEmployeeModal ClearModal={this.ClearModal} />;
    this.setState ({modal}, () => {
      document.getElementById ('modalEmployee-btn').click ();
    });
  };

  ClearModal = () => {
    document.getElementById ('modalEmployee-btn').click ();
    this.setState ({modal: ''});
  };

  HandleClick = e => {
    this.ShowModal ();
  };

  render () {
    return (
      <div className="container">
        {this.state.modal}
        <div className="row my-3 py-1">
          <div className="col">
            <h1>Liste des employés</h1>
          </div>
          <div className="ml-auto">
            <div className="btn btn-reseau">
              <h3 className="p-2">
                Ajouter{' '}
                <i className="fas fa-plus-circle" onClick={this.HandleClick} />
              </h3>
            </div>
          </div>
        </div>
        <EmployeesList employeesList={this.props.employees.employeesList} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  employees: state.employees,
});

export default connect (mapStateToProps, {GetAllEmployees}) (Utilisateurs);
