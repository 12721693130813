import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  GetAllPendingWeeks,
  ApproveWeek,
} from '../../store/actions/weeksActions';
import isEmpty from '../../validation/isEmpty';
import moment from 'moment';
import classnames from 'classnames';
export class AdminWeeksToApprove extends Component {
  constructor (props) {
    super (props);

    this.state = {
      sortedList: {},
    };
  }

  componentDidMount () {
    this.props.GetAllPendingWeeks ();
  }
  componentDidUpdate (prevProps, prevState) {
    if (prevProps.weeks.weeks !== this.props.weeks.weeks) {
      console.log (this.props.weeks.weeks);

      const sortedList = this.SortByEmployee (this.props.weeks.weeks);
      this.setState ({sortedList});
    }
  }

  HandleStatusSelect = e => {
    this.props.ApproveWeek (e.currentTarget.name, e.currentTarget.value);
  };

  RenderHeader = () => {
    return (
      <div className="row my-3 py-1">
        <div className="col-md-12">
          <h1>Suivis des semaines à approuver</h1>
        </div>
      </div>
    );
  };

  SortByEmployee = weeks => {
    let sortedList = {};

    for (var week of weeks) {
      if (
        isEmpty (sortedList[week.employeId.lastName + week.employeId.firstName])
      ) {
        sortedList[week.employeId.lastName + week.employeId.firstName] = {
          info: week.employeId,
          weeks: [],
        };
      }
      sortedList[
        week.employeId.lastName + week.employeId.firstName
      ].weeks.push (week);
      //Keeps the state of the week
      this.setState ({[week._id]: week.status});
    }
    Object.keys (sortedList)
      .sort ()
      .reduce ((a, c) => ((a[c] = sortedList[c]), a), {});
    return sortedList;
  };

  RenderAllPendingWeeks = () => {
    const {sortedList} = this.state;
    const rows = Object.keys (sortedList).map ((key, index) => {
      return this.RenderEmployeeWeeks (sortedList[key], index);
    });
    return rows;
  };

  RenderEmployeeWeeks = (employee, index) => {
    const {weeks} = employee;
    weeks.sort ((a, b) => {
      if (a.period > b.period) {
        return -1;
      } else if (a.period < b.period) {
        return 1;
      } else {
        return 0;
      }
    });

    const select = weeks.map (week =>
      this.RenderWeekSelect (week, employee.info)
    );
    return (
      <div key={index} className="row">
        <div className="col-md-12">
          <h3>{employee.info.firstName} {employee.info.lastName}</h3>
        </div>
        {select}
      </div>
    );
  };

  RenderWeekSelect = (week, employee) => {
    if (isEmpty (week)) return;
    const {hourPerWeek} = employee.contract;

    const totalWorked = week.entries.reduce ((acc, current) => {
      return acc + current.duration;
    }, 0);

    return (
      <div key={week._id + employee._id} className="time-row row my-3 my-md-1">
        <div className="col-md-4 ">
          <p
          >{`Période ${week.period} | Du ${moment
            .utc (week.weekStart)
            .format (
              'YYYY-MM-DD'
            )} au ${moment.utc (week.weekEnd).format ('YYYY-MM-DD')}`}</p>
        </div>
        <div
          className={classnames (
            'col-md-2',
            {
              'overtime-alert': totalWorked > hourPerWeek,
            },
            {
              'undertime-alert': totalWorked < hourPerWeek,
            }
          )}
        >
          <h3>{`${totalWorked} / ${hourPerWeek} heures`}</h3>
          {totalWorked > hourPerWeek &&
            <small>
              +
              {' '}
              {totalWorked - hourPerWeek}
              {' '}
              heure
              {totalWorked - hourPerWeek > 1 && 's'}
            </small>}
          {totalWorked < hourPerWeek &&
            <small>
              -
              {' '}
              {hourPerWeek - totalWorked}
              {' '}
              heure
              {hourPerWeek - totalWorked > 1 && 's'}
            </small>}
        </div>
        <div className="col">
          {this.RenderStatusSelect (week._id, week.status)}
        </div>
      </div>
    );
  };

  RenderStatusSelect = (id, status) => {
    const val = this.state[id];
    return (
      <div className="form-group">
        <label htmlFor={id}>Status</label>
        <select
          className="form-control"
          name={id}
          id={id}
          onChange={this.HandleStatusSelect}
          value={val}
        >
          <option value="UNSENT">Non envoyée pour approbation</option>
          <option value="CONDITIONAL">Doit être modifiée</option>
          <option value="PENDING">En attente d'approbation</option>
          <option value="APPROVED">Semaine approuvée</option>
          <option value="DENIED">Semaine refusée</option>
        </select>
      </div>
    );
  };
  render () {
    return (
      <div className="container">
        {this.RenderHeader ()}
        {this.RenderAllPendingWeeks ()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  employees: state.employees,
  weeks: state.weeks,
});

const mapDispatchToProps = {GetAllPendingWeeks, ApproveWeek};

export default connect (mapStateToProps, mapDispatchToProps) (
  AdminWeeksToApprove
);
