import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {GetAllEmployees} from '../../store/actions/employeesActions';

export class AdminEmployeeSummary extends Component {
  componentDidMount () {
    this.props.GetAllEmployees ();
  }

  RenderHeader = () => {
    return (
      <div className="row my-3 py-1">
        <div className="col-md-12">
          <h1>Suivis des heures par employé</h1>
        </div>
      </div>
    );
  };
  RenderAllEmployees = () => {
    const {employeesList} = this.props.employees;
    return employeesList
      .filter (e => {
        return e.status === 'ACTIVE';
      })
      .sort ((a, b) => {
        return a.lastName > b.lastName;
      })
      .map (employee => {
        return (
          <div className="row employee-row" key={employee._id}>
            <div className="col-md-6">
              <h3>{`${employee.firstName} ${employee.lastName}`}</h3>
            </div>
            <Link to={`/admin/semaines/${employee._id}`}>
              <i className="fas fa-clipboard-list pr-2" />
              Voir les feuilles de temps
            </Link>
          </div>
        );
      });
  };

  render () {
    return (
      <div className="container">
        {this.RenderHeader ()}
        {this.RenderAllEmployees ()}
      </div>
    );
  }
}

const mapStateToProps = state => ({employees: state.employees});

const mapDispatchToProps = {
  GetAllEmployees,
};

export default connect (mapStateToProps, mapDispatchToProps) (
  AdminEmployeeSummary
);
