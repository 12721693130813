import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {GetCurrentEmployee} from '../../store/actions/employeesActions';

export class ViewProfile extends Component {
  componentDidMount () {
    const {id} = this.props.auth.utilisateur;
    this.props.GetCurrentEmployee (id);
  }

  RenderHeader = () => {
    return (
      <div className="row my-3 py-1">
        <div className="col-md-12">
          <h1>Profil d'utilisateur</h1>
        </div>
      </div>
    );
  };
  RenderPersonnalInfos = () => {
    const {
      firstName,
      lastName,
      email,
      employementDate,
    } = this.props.employees.selectedEmployee;
    return (
      <div className="row">
        <div className="col-md-12"><h3>Informations personnelles</h3></div>
        <div className="col-md-4">
          <p><i className="fas fa-user px-3" />{`${firstName} ${lastName}`}</p>
        </div>
        <div className="col-md-6">
          <p><i className="fas fa-envelope px-3" /> Courriel: {email}</p>
        </div>
        <div className="col-md-6">
          <p>
            <i className="fas fa-calendar-alt px-3" />
            {' '}
            Date d'embauche:
            {' '}
            {moment.utc (employementDate).format ('YYYY-MM-DD')}
          </p>
        </div>
      </div>
    );
  };
  RenderContract = () => {
    const {
      title,
      hourPerWeek,
      hourRate,
      vacationsDaysPerYear,
      sickDaysPerYear,
    } = this.props.employees.selectedEmployee.contract;

    return (
      <div className="row">
        <div className="col-md-12"><h3>Contrat</h3></div>
        <div className="col-md-12">
          <p><i className="fas fa-id-card px-3" />{`${title}`}</p>
        </div>
        <div className="col-md-6">
          <p>
            <i className="fas fa-envelope px-3" />
            {' '}
            Heures par semaine:
            {' '}
            {hourPerWeek}
          </p>
        </div>
        <div className="col-md-6">
          <p><i className="fas fa-envelope px-3" /> Taux horaire: {hourRate}</p>
        </div>
        <div className="col-md-6">
          <p>
            <i className="fas fa-envelope px-3" />
            {' '}
            Nb de jours maladie par année:
            {' '}
            {sickDaysPerYear}
          </p>
        </div>
        <div className="col-md-6">
          <p>
            <i className="fas fa-envelope px-3" />
            {' '}
            Jours de vacances par année:
            {' '}
            {vacationsDaysPerYear}
          </p>
        </div>
      </div>
    );
  };
  render () {
    return (
      <div className="container">
        {this.RenderHeader ()}
        {this.RenderPersonnalInfos ()}
        <hr />
        {this.RenderContract ()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  employees: state.employees,
});

const mapDispatchToProps = {GetCurrentEmployee};

export default connect (mapStateToProps, mapDispatchToProps) (ViewProfile);
