import {
  ADD_EMPLOYEE,
  GET_ERRORS,
  GET_CURRENT_EMPLOYEE,
  GET_ALL_EMPLOYEES,
  ARCHIVE_EMPLOYEE,
  UPDATE_EMPLOYEE,
} from './types';

import axios from 'axios';

export const GetCurrentEmployee = id => dispatch => {
  axios
    .get ('/api/employe/employe-courant', id)
    .then (employe => {
      dispatch ({type: GET_CURRENT_EMPLOYEE, payload: employe.data});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};
export const GetEmployee = id => dispatch => {
  axios
    .get ('/api/employe/id', {params: {id}})
    .then (employe => {
      dispatch ({type: GET_CURRENT_EMPLOYEE, payload: employe.data});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};

export const GetAllEmployees = () => dispatch => {
  axios
    .get ('/api/employe/all-active')
    .then (employeList => {
      dispatch ({type: GET_ALL_EMPLOYEES, payload: employeList.data});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};

export const AddEmployee = data => dispatch => {
  axios
    .post ('/api/employe/enregistrement', data)
    .then (employeeList => {
      dispatch ({type: ADD_EMPLOYEE, payload: employeeList.data});
    })
    .then (addedEmployee => {
      dispatch (GetAllEmployees ());
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};

export const UpdateEmployee = data => dispatch => {
  axios
    .put ('/api/employe/update', data)
    .then (employeeList => {
      dispatch ({type: ADD_EMPLOYEE, payload: employeeList.data});
    })
    .then (addedEmployee => {
      dispatch (GetAllEmployees ());
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};

export const ArchiveEmployee = id => dispatch => {
  axios
    .delete ('/api/employe/archive', {params: {id}})
    .then (employe => {
      dispatch ({type: ARCHIVE_EMPLOYEE, payload: employe.data});
    })
    .then (addedEmployee => {
      dispatch (GetAllEmployees ());
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};

export const UnArchiveEmployee = id => dispatch => {
  axios
    .put ('/api/employe/unarchive', {id})
    .then (employe => {
      dispatch ({type: UPDATE_EMPLOYEE, payload: employe.data});
    })
    .then (addedEmployee => {
      dispatch (GetAllEmployees ());
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};
