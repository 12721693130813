// import action
import {
  ADD_ENTRY,
  ASSIGN_ENTRY_WEEK,
  GET_ALL_WEEKS,
  UPDATE_WEEK,
  SET_CURRENT_WEEK,
} from '../actions/types';

const initialState = {
  weeks: [],
  selectedEntry: {},
  selectedWeek: {},
  period: '',
};
const weeksReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ENTRY:
      return {...state, selectedEntry: action.payload};
    case ASSIGN_ENTRY_WEEK:
    case UPDATE_WEEK:
    case SET_CURRENT_WEEK:
      return {...state, selectedWeek: action.payload};
    case GET_ALL_WEEKS:
      return {...state, weeks: action.payload};
    default:
      return state;
  }
};

export default weeksReducer;
