import {GET_ALL_REGIONS, GET_ERRORS} from './types';

import axios from 'axios';

export const GetAllRegions = () => dispatch => {
  axios
    .get ('/api/region/')
    .then (response => {
      return dispatch ({type: GET_ALL_REGIONS, payload: response.data});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};
