import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';
import isEmpty from '../../validation/isEmpty';
import {
  AddEntry,
  RemoveEntry,
  UpdateEntry,
} from '../../store/actions/weeksActions';
import {GetCurrentEmployee} from '../../store/actions/employeesActions';
import moment from 'moment';
moment.tz ('America/Toronto').format ('Z');
moment.locale ('fr');
moment.updateLocale ('fr', {
  week: {
    dow: 0,
  },
});
class AddEntryModal extends Component {
  constructor (props) {
    super (props);
    this.state = {
      fiscalCodesList: [],
      selectedProgram: '',
      entry: {
        employeeId: '',
        date: moment.utc (this.props.sunday).format ('YYYY-MM-DD') ||
          moment ().format ('YYYY-MM-DD'),
        fiscalCode: '',
        duration: 0,
        comments: '',
        approved: false,
      },
    };
  }

  componentDidMount () {
    const {id} = this.props.auth.utilisateur;
    this.props.GetCurrentEmployee (id);

    if (Boolean (this.props.retrievedState)) {
      const updatedState = {...this.props.retrievedState};
      updatedState.date = moment
        .utc (this.props.retrievedState.date)
        .format ('YYYY-MM-DD');
      updatedState.fiscalCode = this.props.retrievedState.fiscalCode._id;
      const selectedProgram = this.props.retrievedState.fiscalCode.code_program
        ._id;
      this.setState ({entry: updatedState, selectedProgram}, () => {
        this.setState ({fiscalCodesList: this.FilterList (selectedProgram)});
      });
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.programs !== this.props.programs) {
      if (this.props.action !== 'UPDATE' && !isEmpty (this.props.program)) {
        const {fiscalCodesList} = this.props.programs;
        const {entry} = this.state;
        entry.fiscalCode = fiscalCodesList[0]._id;
        this.setState ({entry});
      }
    }

    if (prevProps.employees !== this.props.employees) {
      if (this.props.employees.selectedEmployee) {
        const {entry} = this.state;
        entry.employeeId = this.props.employees.selectedEmployee;
        this.setState ({entry});
      }
    }
  }

  //**************** */
  OnHandleSelect = e => {
    const {entry} = this.state;
    entry[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({entry});
  };

  OnHandleDate = e => {
    const {entry} = this.state;
    entry[e.currentTarget.name] = moment
      .utc (e.currentTarget.value)
      .format ('YYYY-MM-DD');
    this.setState ({entry});
  };

  OnHandleText = e => {
    const {entry} = this.state;
    entry[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({entry});
  };

  OnProgramSelect = e => {
    const selectedProgram = e.currentTarget.value;
    this.OnProgramChange (selectedProgram);
    this.setState ({selectedProgram});
  };

  FilterList = code_program => {
    const {fiscalCodesList} = this.props.programs;

    const list = fiscalCodesList.filter (code => {
      return code.code_program._id === code_program;
    });
    return list;
  };

  OnProgramChange = code_program => {
    const {entry} = this.state;
    const list = this.FilterList (code_program);
    entry.fiscalCode = list.length > 0 ? list[0]._id : '';
    this.setState ({fiscalCodesList: list, entry});
  };

  OnActivityChange = e => {
    this.OnHandleSelect (e);
  };

  AddEntry = () => {
    const {entry} = this.state;
    this.props.AddEntry (entry);
  };

  UpdateEntry = () => {
    const {entry} = this.state;
    this.props.UpdateEntry (entry);
  };
  DeleteEntry = () => {
    const {_id} = this.state.entry;
    this.props.RemoveEntry (_id);
  };

  //******** VIEW SECTIONS
  ModalBody = () => {
    return (
      <div className="row">
        <div className="col-md-6">{this.DateInput ()}</div>
        <div className="col-md-6">{this.DurationInput ()}</div>
        <div className="col-md-6">{this.ProgramSelect ()}</div>
        <div className="col-md-6">{this.FiscalCodeSelect ()}</div>
        <div className="col-md-12">{this.CommentsInput ()}</div>
      </div>
    );
  };

  DateInput = () => {
    const {date} = this.state.entry;
    return (
      <div className="form-group">
        <label htmlFor="date">Date</label>
        <input
          type="date"
          className="form-control"
          name="date"
          id="date"
          value={date}
          onChange={this.OnHandleDate}
        />
      </div>
    );
  };

  DurationInput = () => {
    const {duration} = this.state.entry;
    return (
      <div className="form-group">
        <label htmlFor="duration">Durée</label>
        <input
          type="number"
          className="form-control"
          name="duration"
          min="0"
          step="0.05"
          id="duration"
          value={duration}
          onChange={this.OnHandleText}
        />
      </div>
    );
  };

  CommentsInput = () => {
    const {comments} = this.state.entry;
    return (
      <div className="form-group">
        <label htmlFor="comments">Commentaires</label>
        <textarea
          className="form-control"
          name="comments"
          id="comments"
          rows="3"
          onChange={this.OnHandleText}
          value={comments}
        />
      </div>
    );
  };

  FiscalCodeSelect = () => {
    const {fiscalCodesList} = this.state;
    const opts = fiscalCodesList.map (this.CodesOptions);
    const {fiscalCode} = this.state.entry;
    return (
      <div className="form-group">
        <label htmlFor="fiscalCode">Code de temps</label>
        <select
          className="custom-select fiscal-code-sel"
          name="fiscalCode"
          id="fiscalCode"
          value={fiscalCode}
          onChange={this.OnActivityChange}
        >

          {opts}
        </select>
      </div>
    );
  };

  ProgramSelect = () => {
    const {programsList} = this.props.programs;
    const opts = programsList.map (this.ProgramsOptions);
    const {selectedProgram} = this.state;
    return (
      <div className="form-group">
        <label htmlFor="selectedProgram">Programme</label>
        <select
          className="custom-select"
          name="selectedProgram"
          id="selectedProgram"
          value={selectedProgram}
          onChange={this.OnProgramSelect}
        >
          <option value="">Choisir un programme</option>
          {opts}
        </select>
      </div>
    );
  };

  CodesOptions = (fiscalCode, index) => {
    const {code_id} = fiscalCode;
    const cat = code_id.substr (0, 1);
    return (
      <option
        className={classnames ('code-opt', `code-${cat}`)}
        value={fiscalCode._id}
        key={index}
      >
        {fiscalCode.code_id}
        {' '}
        -
        {' '}
        {fiscalCode.code_description[0].toUpperCase () +
          fiscalCode.code_description.slice (1).toLowerCase ()}
      </option>
    );
  };

  ProgramsOptions = (program, index) => {
    return (
      <option value={program._id} key={index}>
        {program.prog_code}
        {' '}
        -
        {' '}
        {program.prog_description[0].toUpperCase () +
          program.prog_description.slice (1).toLowerCase ()}
      </option>
    );
  };

  render () {
    const {entry} = this.state;
    const {date, duration, fiscalCode, employeeId} = entry;
    const isValid =
      !isEmpty (date) &&
      !isEmpty (duration) &&
      !isEmpty (fiscalCode) &&
      !isEmpty (employeeId);

    return (
      <Fragment>
        <button
          type="button"
          className="btn btn-primary d-none"
          data-toggle="modal"
          data-target="#modalNewEntry"
          id="modalEntry-btn"
        />
        <div
          className="modal fade"
          id="modalNewEntry"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modalEntryLabel"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content ">
              <div className="modal-header">
                <h3 className="modal-title text-center" id="modalEntryLabel">
                  Nouvelle entrée de temps
                </h3>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="closeModalBtn"
                  onClick={this.props.ClearModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <hr />
              <div className="modal-body" id="modalEntry-body">
                {this.ModalBody ()}
              </div>
              <div className="modal-footer">
                {this.props.action !== 'UPDATE' &&
                  <button
                    type="button"
                    className={classnames ('btn btn-primary', {
                      'btn-disabled': !isValid,
                    })}
                    onClick={this.AddEntry}
                  >
                    Ajouter
                  </button>}
                {this.props.action === 'UPDATE' &&
                  <button
                    type="button"
                    className={classnames ('btn btn-primary', {
                      'btn-disabled': !isValid,
                    })}
                    onClick={this.UpdateEntry}
                  >
                    Modifier
                  </button>}
                {this.props.action === 'UPDATE' &&
                  <button
                    type="button"
                    className={classnames ('btn btn-danger', {
                      'btn-disabled': !isValid,
                    })}
                    onClick={this.DeleteEntry}
                  >
                    Supprimer
                  </button>}
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={this.props.ClearModal}
                >
                  Annuler
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  programs: state.programs,
  entry: state.entry,
  employees: state.employees,
});

export default connect (mapStateToProps, {
  AddEntry,
  GetCurrentEmployee,
  RemoveEntry,
  UpdateEntry,
}) (AddEntryModal);
