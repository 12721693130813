import {GET_ALL_PROGRAMS, GET_ALL_CODES, ADD_PROGRAM} from '../actions/types';

const initialState = {
  programsList: [],
  fiscalCodesList: [],
  program: {},
};
const programsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PROGRAMS:
      return {...state, programsList: action.payload};
    case GET_ALL_CODES:
      return {...state, fiscalCodesList: action.payload};
    case ADD_PROGRAM:
      return {...state, program: action.payload};
    default:
      return state;
  }
};

export default programsReducer;
