import React, {Component} from 'react';
import {connect} from 'react-redux';

import NewProgrammModal from '../../programs/NewProgramModal';
import FiscalCodesList from '../../programs/FiscalCodesList';
import {
  GetAllPrograms,
  GetAllCodes,
} from '../../../store/actions/programsActions';

// TODO: Add function to add new programme
class IndicesComptables extends Component {
  constructor (props) {
    super (props);
    this.state = {
      modal: '',
    };
  }

  componentDidMount () {
    this.props.GetAllPrograms ();
    this.props.GetAllCodes ();
  }

  ShowModal = () => {
    const modal = <NewProgrammModal ClearModal={this.ClearModal} />;

    this.setState ({modal}, () => {
      document.getElementById ('modalProgram-btn').click ();
    });
  };

  ClearModal = () => {
    document.getElementById ('modalProgram-btn').click ();
    this.setState ({modal: ''});
  };

  HandleClick = e => {
    this.ShowModal ();
  };

  render () {
    return (
      <div className="container">
        {this.state.modal}
        <div className="row my-3 py-1">
          <div className="col">
            <h1>Liste des indices comptables</h1>
          </div>
          <div className="ml-auto">
            <div className="btn btn-reseau">
              <h3 className="p-2">
                Ajouter
                {' '}
                <i className="fas fa-plus-circle" onClick={this.HandleClick} />
              </h3>

            </div>
          </div>
        </div>
        <FiscalCodesList />
      </div>
    );
  }
}

//TODO:Is it necessary
const mapStateToProps = state => ({
  programs: state.programs,
});

export default connect (mapStateToProps, {GetAllPrograms, GetAllCodes}) (
  IndicesComptables
);
